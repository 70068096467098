import { React } from "react";
import { Component } from "react";
import CreateIcon from "@material-ui/icons/Create";
import axios from "axios";
import {
  ValidateEmail,
  normalize,
  formatAsCurrency,
} from "../../../config/utility";
import { connect } from "react-redux";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import { addSettings, addMsaInfo } from "../../../actions";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import {
  DateTimePicker,
  DatePicker,
  TimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import dateFormat, { masks } from "dateformat";
import {
  getSingleAgent,
  sendEmail,
  addAgentsCommissionSettings,
  getAgentCommissionSettings,
  getAgentCommissionStatistics,
  addFundsInAgentsAccount,
  getAgentOrders,
  getPaidCommissionLogs,
} from "./../../../config/api_calls";
import {
  AGENT_PORTAL_URL,
  AGENT_SERVER_URL,
  APP_URL,
} from "../../../config/config";
import HelperClass from "./../../../config/helperClass";
import $ from "jquery";
import DataTable from "../../../components/Tables/DataTable";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import { tableLoaderListing } from "../../../components/ContentLoaders/table_loader_listing";
import { HelpLinksLoader } from "../../../components/ContentLoaders/newLoaders/HelpLinksLoader";
import { UpworkJobLoader } from "../../../components/ContentLoaders/newLoaders/UpworkJobLoader";
import DataTableExtended from "../../../components/Tables/DataTableExtended";
import PageTitle from "../ExtraComponents/PageTitle";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import HomeIcon from "@material-ui/icons/Home";
import StepWizard from "react-step-wizard";
import Tooltip from "@material-ui/core/Tooltip";
import { CopyToClipboard } from "react-copy-to-clipboard";
import MutextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/build/index.css";
import Select2 from "react-select";
import countryList from "react-select-country-list";
import Checkbox from "@material-ui/core/Checkbox";
import { PhoneNumberUtil } from "google-libphonenumber";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import localization from "../../../localization/enUS";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";

import makeAnimated from "react-select/animated";
const animatedComponents = makeAnimated();

const auth = new HelperClass();
class Agents extends Component {
  constructor() {
    super();
    this.decimalPoint = Number(auth.getDecimalPoint());
    this.state = {
      tableData: [],
      didArray: [],
      tfnArray: [],
      termArray: [],
      smsArray: [],
      liveagentArray: [],
      ivrArray: [],
      subAgentsData: [],
      columnCommissionLogs: [],
      assignedLoader: true,
      activeTab: "statistics",
      errorMessage: "",
      errorMessageEdit: "",
      agentId: "",
      successMessage: "",
      successMessageEdit: "",
      eSuccessMessage: "",
      eErrorMessage: "",
      waitMessage: "",
      tableLoader: true,
      signup_link: "",
      copied: false,
      cpied_value: "",
      current_id: "",
      reg_button: false,
      cancel_button: false,
      title: "",
      send_email: "",
      email_to: "",
      name: "",
      logo: "",
      agent_email: "",
      agent_phone: "",
      agent_company: "",
      agent_status: "",
      agent_password: "",
      agent_address: "",
      agent_address2: "",
      agent_city: "",
      agent_state: "",
      agent_zip: "",
      agent_country: "",
      sharelink_limit: "",
      activation_button: false,
      activation_tick: false,
      activation_cross: false,
      already_activated: false,
      c_password: "",
      agent_id: "",
      loginErrorMessage: "",
      login_url: "",
      serviceData: [],
      signedData: [],
      unsignedData: [],
      isEmailSending: false,
      unsigned_email_agreement_id: 0,
      revoke_reason: "",
      isRevokeReasonActive: false,
      isApproveActive: false,
      approve_agreement_id: "",
      approve_agreement_accountno: "",
      tableLoaderListing: true,
      columnBundleServices: [],
      signup_link_agent: "",
      login_url_agent: "",
      commission_button: false,
      commissionErrorMessage: "",
      commissionSuccessMessage: "",
      commissionErrorTitle: "",
      commission_type: "paid_on_collected",
      commission_value_type: "percentage",
      apply_commission: "",
      draw_threshold: (0).toFixed(this.decimalPoint),
      commission_value: "",
      copied1: false,
      copied2: false,
      agent_total_sales: 0,
      agent_total_commision: 0,
      agent_paid_commision: 0,
      agent_pending_commision: 0,
      show_total_sales: true,
      show_total_commision: true,
      show_paid_commision: true,
      show_pending_commision: true,
      pay_now_button: false,
      pay_now_form: false,
      pay_now_form_block: false,
      payment_amount: (0).toFixed(this.decimalPoint),
      fundErrorMessage: "",
      fundSuccessMessage: "",
      add_funds_button: false,
      notifyCheckBox: false,

      errorMessageAgentRole: "",
      successMessageAgentRole: "",
      assignRoleData: [],
      assignSubRoleData: [],
      changedRolesRights: [],
      changedSubRolesRights: [],
      errorMessageSubAgentRole: "",
      successMessageSubAgentRole: "",
      subassignRoleData: [],
      subassignSubRoleData: [],
      subchangedRolesRights: [],
      subchangedSubRolesRights: [],
      checkedRoleIds: [],
      checkedSubRoleIds: [],
      checkedName: "",
      checkedEmail: "",
      statusChanged: false,

      errorName: "",
      errorAgentStatus: "",
      errorAgentCompany: "",
      errorAgentEmail: "",
      errorAgentPhone: "",
      errorAgentAddress: "",
      errorAgentCity: "",
      errorAgentState: "",
      errorAgentZip: "",
      errorAgentCountry: "",
      errorAgentPassword: "",
      errorCPassword: "",
      errorAgentSharelink: "",

      agentErrorMessage: "",
      agentSuccessMessage: "",
      agent_name: "",
      agent_email: "",

      agentInviteErrorMessage: "",
      agentInviteSuccessMessage: "",
      useGooglePlaces: true,
      toggleButtonText: "Allow PO Box Address",

      agent_isVerified: "",
      invitation_comment: "",

      payment_receipt: "",
      payment_notes: "",
      payment_date: new Date(),
      payment_method_pay: "Quickbooks",
      add_picture: "",
      add_picture_name: "",
      agentOrdersData: [],
      allAgentOrders: [],
      orderno: "",
      order_details: null,
      commission_logs_loader: false,
      commissionLogs: [],
      view_commission_type: "",
      view_commission_value: "",
      view_commission_value_type: "",
      view_date_added: "",
      view_payment_details: [],
      viewLoader: false,
    };
    // Bind the event handler functions to the current instance
    this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    this.countryOptions = countryList().getData();
  }

  async componentDidMount() {
    let agent_id = this.props.props.match.params.agent_accountno;
    this.setState({ agentId: agent_id });
    this.getSingleAgent(agent_id);
    this.get_commission_statistics(agent_id);
  }

  format_date = (value, tableMeta) => {
    let date = value;
    return dateFormat(date, "mmmm dS, yyyy");
  };

  phoneUtil = PhoneNumberUtil.getInstance();

  isPhoneValid = (phone) => {
    try {
      return this.phoneUtil.isValidNumber(
        this.phoneUtil.parseAndKeepRawInput(phone)
      );
    } catch (error) {
      return false;
    }
  };

  handleDateChange = (newDate) => {
    this.setState({ payment_date: newDate });
  };

  changeActiveTab = (tab) => {
    this.setState({
      activeTab: tab,
      assignedLoader: true,
    });
  };

  getSingleAgent = async (agent_id) => {
    const servicesResponce = await getSingleAgent(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    console.log("servicesResponce.data.data: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let agent_status = "0";
      if (
        servicesResponce.data.data[0][6] &&
        servicesResponce.data.data[0][6] === "active"
      ) {
        agent_status = "1";
      }
      this.setState({
        title: servicesResponce.data.data[0][0],
        name: servicesResponce.data.data[0][1],
        agent_email: servicesResponce.data.data[0][2],
        agent_phone: servicesResponce.data.data[0][3],
        agent_id: servicesResponce.data.data[0][4],
        agent_company: servicesResponce.data.data[0][5],
        agent_status: agent_status,
        sharelink_limit: servicesResponce.data.data[0][7],
        agent_address: servicesResponce.data.data[0][8],
        agent_address2: servicesResponce.data.data[0][9],
        agent_city: servicesResponce.data.data[0][10],
        agent_state: servicesResponce.data.data[0][11],
        agent_zip: servicesResponce.data.data[0][12],
        agent_country: servicesResponce.data.data[0][13],
        agent_isVerified: servicesResponce.data.data[0][14],
        tableLoader: false,
      });
    } else {
      // window.location.replace("/error");
    }
  };

  handleChangeCountry = (event) => {
    console.log("event.label: ", event.label);
    const country = event.label;

    this.setState({
      agent_country: country,
    });
  };

  setPhone = (value, tableMeta) => {
    //console.log("tableMeta.rowData[5]: ", tableMeta.rowData[5]);
    if (tableMeta.rowData[4] !== "") {
      //let phone = tableMeta.rowData[4].replace("+1", "");
      let phone = this.formatPhoneNumber(tableMeta.rowData[4]);
      return phone;
    } else {
      return "---";
    }
  };

  formatPhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(phoneNumber);
    if (phoneNumberObj) {
      if (phoneNumberObj.country === "US") {
        const nationalNumber = phoneNumberObj
          .formatNational()
          .replace(/\D/g, ""); // e.g. "2123727200"
        const areaCode = nationalNumber.slice(0, 3);
        const firstPart = nationalNumber.slice(3, 6);
        const secondPart = nationalNumber.slice(6);
        return `(${areaCode}) ${firstPart}-${secondPart}`;
      }
      return phoneNumberObj.formatInternational();
    }
    return phoneNumber; // Return original number if parsing fails
  };

  show_email = () => {
    this.setState({
      send_email: "1",
    });
  };

  get_agent_orders = async () => {
    let { agentId } = this.state;
    this.setState({
      fundErrorMessage: "",
      fundSuccessMessage: "",
      pay_now_form_block: true,
    });
    let apiResponce = await getAgentOrders(
      auth.getAccount(),
      auth.getToken(),
      agentId
    );
    console.log("get_agent_orders: ", apiResponce.data);
    if (
      apiResponce.data.status === 403 ||
      apiResponce.data.errors === "authentication missing" ||
      apiResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (
      apiResponce.data.status === 200 &&
      apiResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (apiResponce.data.data && apiResponce.data.data.length > 0) {
        for (let i = 0; i < apiResponce.data.data.length; i++) {
          let newArr = {
            label:
              apiResponce.data.data[i]["orderno"] +
              " - " +
              apiResponce.data.data[i]["client"] +
              " - " +
              formatAsCurrency(apiResponce.data.data[i]["order_total"]) +
              " - Comm. " +
              formatAsCurrency(apiResponce.data.data[i]["order_commission"]),
            value: apiResponce.data.data[i]["orderno"],
          };
          all_clients.push(newArr);
        }
      }
      this.setState({
        allAgentOrders: all_clients,
        agentOrdersData: apiResponce.data.data,
        pay_now_form: true,
        fundErrorMessage: "",
        fundSuccessMessage: "",
        pay_now_form_block: false,
      });
    } else {
      this.setState({
        pay_now_form: true,
        fundErrorMessage: "",
        fundSuccessMessage: "",
        pay_now_form_block: false,
      });
    }
  };

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);

    if (changed_value) {
      let remaining_commission = await this.getCommissionDifference(
        changed_value.value
      );
      console.log("remaining_commission: ", remaining_commission);
      this.setState(
        (prevState) => ({
          orderno: changed_value.value,
          order_details: changed_value,
          payment_amount: remaining_commission,
        }),
        () => {
          // This callback function is called right after the state is updated
          console.log("Updated state:", this.state.orderno);
        }
      );
    } else {
      this.setState(
        {
          orderno: null,
          order_details: null,
          payment_amount: (0).toFixed(this.decimalPoint),
        },
        () => {
          console.log("Cleared selection");
        }
      );
    }
  };

  getCommissionDifference = async (orderNo) => {
    // Find the order with the matching orderno
    console.log("orderNo: ", orderNo);
    const { agentOrdersData } = this.state;
    console.log("agentOrdersData: ", agentOrdersData);
    const order = agentOrdersData.find((order) => order.orderno === orderNo);
    console.log("order After: ", order);
    // If order is found, calculate and return the difference
    if (order) {
      return Number(
        order.order_commission - order.total_paid_commission
      ).toFixed(this.decimalPoint);
    } else {
      // If no order is found, return null or an appropriate message
      return (0).toFixed(this.decimalPoint);
    }
  };

  send_email = async () => {
    let { email_to } = this.state;
    this.setState({
      eSuccessMessage: "",
      eErrorMessage: "",
    });
    let is_validated = true;
    if (this.state.email_to === "") {
      is_validated = false;
    }
    if (is_validated) {
      let apiResponce = await sendEmail(
        auth.getAccount(),
        auth.getToken(),
        this.state.email_to,
        this.state.signup_link,
        this.state.current_id
      );
      console.log("apiResponce.send_email: ", apiResponce.data);
      if (
        apiResponce.data.status === 403 ||
        apiResponce.data.errors === "authentication missing" ||
        apiResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (
        apiResponce.data.status === 200 &&
        apiResponce.data.message === "Success"
      ) {
        this.setState({
          eSuccessMessage: "Email sent successfully.",
          eErrorMessage: "",
          disabled: false,
        });
      } else {
        this.setState({
          eErrorMessage: "Something went wrong,try again later.",
          disabled: false,
          eSuccessMessage: "",
        });
      }
    } else {
      this.setState({
        eSuccessMessage: "",
        eErrorMessage: "Please enter the email address.",
      });
    }
    setTimeout(() => {
      this.setState({
        eSuccessMessage: "",
        eErrorMessage: "",
      });
    }, 4000);
  };

  handleChange = (event) => {
    const { target } = event;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    if (name === "agent_status") {
      this.setState({ statusChanged: true });
      console.log("Status Changed:", true);
    }
    if (name === "name") {
      value = value.replace(/[^a-zA-Z\s]/g, "");
    } else if (name === "agent_company") {
      value = value.replace(/[^a-zA-Z0-9\s -]/g, "");
    } else if (name === "title") {
      value = value.replace(/[^a-zA-Z0-9\s -]/g, "");
    }
    this.setState({
      [name]: value,
    });
  };

  handleChangeRadio = (event) => {
    const { target } = event;
    const value = target.type === "radio" ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  addMenuHistory = (link) => {
    this.props.props.history.push(link);
  };

  downloadPdf = async (d_f_name, title) => {
    if (d_f_name) {
      var url =
        process.env.REACT_APP_AGENT_PORTAL_URL +
        "files_data/agreements/" +
        d_f_name;
      //console.log("File URL: ", url);
      var pdf_name = title.replace(/[^a-zA-Z ]/g, "");
      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = pdf_name + ".pdf";
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage: "There is some error while downloading the PDF.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    }
  };

  downloadReceipt = async (d_f_name) => {
    if (d_f_name) {
      var url =
        process.env.REACT_APP_AGENT_PORTAL_URL +
        "files_data/receipts/" +
        d_f_name;
      //console.log("File URL: ", url);

      fetch(url)
        .then((x) => x.blob())
        .then((b) => {
          // console.log("B INSTANCE", b instanceof Blob);
          const url = window.URL.createObjectURL(b);
          var a = document.createElement("a");
          document.body.appendChild(a);
          a.style = "display: none";
          a.href = url;
          a.download = d_f_name;
          a.click();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          this.setState({
            errorMessage: "There is some error while downloading the receipt.",
          });
          setTimeout(() => {
            this.setState({ errorMessage: "" });
          }, 4000);
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    }
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  handlePhoneNumberChange(value) {
    this.setState({
      agent_phone: value,
    });
  }

  getAgentCommission = async (value, tableMeta) => {
    let agent_id = tableMeta.rowData[7];
    this.setState({
      company_title: tableMeta.rowData[2] + " (" + tableMeta.rowData[1] + ")",
      agent_id: agent_id,
      fundErrorMessage: "",
      fundSuccessMessage: "",
    });
    window.$("#modalAgentCommission").modal("show");
    this.get_commission_statistics(agent_id);
  };

  get_commission_statistics = async (agent_id) => {
    //const { agent_id } = this.state;
    const servicesResponce = await getAgentCommissionStatistics(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    console.log("get_commission_statistics: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        show_total_sales: false,
        show_total_commision: false,
        show_paid_commision: false,
        show_pending_commision: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        agent_total_sales: servicesResponce.data.total_sales
          ? servicesResponce.data.total_sales
          : 0,
        agent_total_commision: servicesResponce.data.total_commission
          ? servicesResponce.data.total_commission
          : 0,
        agent_paid_commision: servicesResponce.data.total_paid
          ? servicesResponce.data.total_paid
          : 0,
        agent_pending_commision: servicesResponce.data.total_pending
          ? servicesResponce.data.total_pending
          : 0,
        show_total_sales: false,
        show_total_commision: false,
        show_paid_commision: false,
        show_pending_commision: false,
      });
    } else {
      this.setState({
        show_total_sales: false,
        show_total_commision: false,
        show_paid_commision: false,
        show_pending_commision: false,
      });
    }
  };

  addFundsInAgentAccount = async () => {
    const {
      agent_id,
      payment_amount,
      agent_pending_commision,
      payment_date,
      payment_method_pay,
      payment_notes,
      add_picture,
      add_picture_name,
      orderno,
    } = this.state;
    this.setState({
      add_funds_button: true,
      fundErrorMessage: "",
      fundSuccessMessage: "",
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use "smooth" for a smooth scroll effect
    });
    let remaining_commission = 0;
    if (orderno && orderno !== "") {
      remaining_commission = await this.getCommissionDifference(orderno);
    }
    console.log("remaining_commission: ", remaining_commission);
    if (payment_amount <= 0) {
      this.setState({
        fundErrorMessage: "Amount should be greater than 0.",
        add_funds_button: false,
      });
    } else if (payment_amount > agent_pending_commision) {
      this.setState({
        fundErrorMessage:
          "Amount should be less than or equal to pending amount.",
        add_funds_button: false,
      });
    } else if (
      orderno &&
      orderno !== "" &&
      payment_amount > remaining_commission
    ) {
      this.setState({
        fundErrorMessage:
          "Amount should be less than or equal to order pending commission which is $ (" +
          Number(remaining_commission).toFixed(this.decimalPoint) +
          ").",
        add_funds_button: false,
      });
    } else {
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("accountno", auth.getAccount());
      formData.append("file", add_picture);
      formData.append("fileName", add_picture_name);
      formData.append("payment_date", payment_date);
      formData.append("payment_method_pay", payment_method_pay);
      formData.append("payment_notes", payment_notes);
      formData.append("orderno", orderno);
      formData.append("agent_id", agent_id);
      formData.append("payment_amount", payment_amount);

      try {
        axios
          .post(
            AGENT_SERVER_URL + "resellers/add_funds_in_agents_account",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": true,
              },
            },
            {}
          )
          .then((response) => {
            console.log("submitSignatory response.data : ", response.data);
            if (response.data.status === 404) {
              this.setState({
                fundErrorMessage:
                  "There is some error while adding the funds. Please try again.",
                add_funds_button: false,
              });
            } else if (response.data.status === 403) {
              this.setState({
                fundErrorMessage:
                  "There is some error while adding the funds. Please try again.",
                add_funds_button: false,
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                fundErrorMessage: "",
                fundSuccessMessage:
                  "Funds added successfully to the channel partner account. Please wait while we are refreshing the page.",
                agent_paid_commision: response.data.total_paid
                  ? response.data.total_paid
                  : 0,
                agent_pending_commision: response.data.total_pending
                  ? response.data.total_pending
                  : 0,
                //add_funds_button: false,
              });
              setTimeout(async function () {
                //window.$("#modalFormDetail").modal("hide");
                window.location.reload();
              }, 5000);
            } else {
              this.setState({
                fundErrorMessage:
                  "There is some error while adding the funds. Please try again.",
                add_funds_button: false,
              });
            }
          });
      } catch (error) {
        this.setState({
          fundErrorMessage:
            "There is some error while adding the funds. Please try again.",
          add_funds_button: false,
        });

        console.log("catch : ", error);
      }
    }
    setTimeout(() => {
      this.setState({
        fundErrorMessage: "",
        fundSuccessMessage: "",
        add_funds_button: false,
      });
    }, 5000);
  };

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation selectedFiles PDF, DOC, DOCX, ZIP, TXT, PNG, JPEG, JPG
    const allowedFileTypes = [".png", ".jpg", ".jpeg", ".pdf"];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        uploadErrorMessage:
          "Invalid file type. Please select a valid file format.",
        uploadErrorTitle: "Invalid File",
        add_picture: "",
        add_picture_name: "",
      });
      return;
    }

    // File size validation (e.g., limit to 10MB)
    const maxSizeBytes = 10 * 1024 * 1024; // 10MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        uploadErrorMessage:
          "File size exceeds the limit (10MB). Please choose a smaller file.",
        uploadErrorTitle: "Invalid File",
        add_picture: "",
        add_picture_name: "",
      });
      return;
    }

    // Clear any previous errors
    this.setState({
      add_picture: selectedFile,
      uploadErrorMessage: "",
      uploadErrorTitle: "",
      add_picture_name: selectedFile.name,
    });
    //return true;
  };

  get_commission_logs = async () => {
    const { agent_id } = this.state;
    this.setState({
      commissionLogError: "",
      commission_logs_loader: true,
    });
    const servicesResponce = await getPaidCommissionLogs(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    console.log("get_commission_logs: ", servicesResponce.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        commissionLogError: "There are no commission logs right now.",
        commission_logs_loader: false,
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        commissionLogs: servicesResponce.data.data,
        commission_logs_loader: false,
        commissionLogError: "",
        columnCommissionLogs: [
          {
            name: "orderDetail",
            label: "Order Total / Client",
          },
          {
            name: "orderno",
            label: "Order #",
          },
          {
            name: "total_commission",
            label: "Total Commission",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{formatAsCurrency(value)}</div>;
              },
            },
          },
          {
            name: "approved",
            label: "Approved",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_approved(value)}</div>;
              },
            },
          },
          {
            name: "paid",
            label: "Paid",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return <div>{this.format_paid(value)}</div>;
              },
            },
          },
          {
            name: "orderno",
            label: "Actions",
            options: {
              customBodyRender: (value, tableMeta, updateValue) => {
                return (
                  <div>
                    <div
                      className="nk-tb-col nk-tb-col-tools"
                      style={{ padding: "0px" }}
                    >
                      <ul className="nk-tb-actions gx-1">
                        <li>
                          <div className="drodown">
                            <a
                              style={{ cursor: "pointer" }}
                              title="Other Actions"
                              className="dropdown-toggle btn btn-icon  btn-outline-light"
                              data-toggle="dropdown"
                            >
                              <em className="icon ni ni-more-h"></em>
                            </a>
                            <div className="dropdown-menu dropdown-menu-md dropdown-menu-right customStyle_dropdown-menu-md_forms">
                              <ul className="link-list-opt no-bdr">
                                <li>
                                  <Tooltip title="View Details" placement="top">
                                    <a
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        this.viewLogDetail(value, tableMeta)
                                      }
                                    >
                                      <em class="icon ni ni-card-view"></em>
                                      <span>View Details</span>
                                    </a>
                                  </Tooltip>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              },
            },
          },
          {
            name: "commission_type",
            label: "commission_type",
            options: {
              display: false,
            },
          },
          {
            name: "commission_value",
            label: "commission_value",
            options: {
              display: false,
            },
          },
          {
            name: "commission_value_type",
            label: "commission_value_type",
            options: {
              display: false,
            },
          },
          {
            name: "payment_details",
            label: "paymentDetails",
            options: {
              display: false,
            },
          },
          {
            name: "dateAdded",
            label: "dateAdded",
            options: {
              display: false,
            },
          },
        ],
      });
    } else {
      this.setState({
        commissionLogError:
          "There is some error while getting the commission logs.",
        commission_logs_loader: false,
      });
    }
  };

  format_paid = (value) => {
    if (value === 1) {
      return (
        <span className="badge badge-outline-success">
          <em class="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
          <em className="icon ni ni-caution"></em> No
        </span>
      );
    }
  };

  format_approved = (value) => {
    if (value === 1) {
      return (
        <span className="badge badge-outline-success">
          <em class="icon ni ni-done"></em> Yes
        </span>
      );
    } else {
      return (
        <span className="badge badge-outline-light">
          <em className="icon ni ni-caution"></em> No
        </span>
      );
    }
  };

  viewLogDetail = async (value, tableMeta) => {
    console.log(tableMeta.rowData);
    this.setState({
      viewLoader: true,
      view_commission_type: tableMeta.rowData[6],
      view_commission_value: tableMeta.rowData[7],
      view_commission_value_type: tableMeta.rowData[8],
      view_date_added: tableMeta.rowData[10],
      view_payment_details: tableMeta.rowData[9],
    });
    window.$("#modalCommissionLogs").modal("show");
    setTimeout(() => {
      this.setState({ viewLoader: false });
    }, 2000);
  };

  get_commission_settings = async () => {
    const { agent_id } = this.state;
    const servicesResponce = await getAgentCommissionSettings(
      auth.getAccount(),
      auth.getToken(),
      agent_id
    );
    console.log("get_commission_settings: ", servicesResponce.data.data);
    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        apply_commission: servicesResponce.data.data[0].apply_commission,
        commission_type: servicesResponce.data.data[0].commission_type,
        commission_value: servicesResponce.data.data[0].commission_value,
        draw_threshold: servicesResponce.data.data[0].draw_threshold
          ? servicesResponce.data.data[0].draw_threshold.toFixed(
              this.decimalPoint
            )
          : (0).toFixed(this.decimalPoint),
        commission_value_type:
          servicesResponce.data.data[0].commission_value_type,
      });
    } else {
    }
  };

  update_commission_settings = async () => {
    let {
      commission_type,
      commission_value_type,
      commission_value,
      apply_commission,
      agent_id,
      draw_threshold,
    } = this.state;
    this.setState({
      commissionErrorTitle: "",
      commissionErrorMessage: "",
      commissionSuccessMessage: "",
      commission_button: true,
    });
    var is_validated = true;
    console.log("agent_id: ", agent_id);
    if (commission_value === "") {
      this.setState({
        commissionErrorTitle: "Missing Field",
        commissionErrorMessage: "Commission value is required.",
        commission_button: false,
      });
      is_validated = false;
    }

    if (is_validated) {
      const servicesResponce = await addAgentsCommissionSettings(
        auth.getAccount(),
        auth.getToken(),
        agent_id,
        commission_type,
        commission_value_type,
        commission_value,
        apply_commission,
        draw_threshold
      );

      console.log("update_commission_settings: ", servicesResponce.data);

      if (
        servicesResponce.data.status === 403 ||
        servicesResponce.data.errors === "authentication missing" ||
        servicesResponce.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (servicesResponce.data.status === 404) {
        this.setState({
          commissionErrorTitle: "System Error",
          commissionErrorMessage:
            "There is some error while adding commission settings.",
          commissionSuccessMessage: "",
          commission_button: false,
        });
      } else if (
        servicesResponce.data.status === 200 &&
        servicesResponce.data.message === "success"
      ) {
        this.setState({
          commissionErrorTitle: "",
          commissionErrorMessage: "",
          commissionSuccessMessage: "Commission settings added successfully.",
          commission_button: false,
        });
      } else {
        this.setState({
          commissionErrorTitle: "System Error",
          commissionErrorMessage:
            "There is some error while adding commission settings.",
          commissionSuccessMessage: "",
          commission_button: false,
        });
      }
    }
    setTimeout(() => {
      this.setState({
        commissionErrorMessage: "",
        commissionErrorTitle: "",
        commissionSuccessMessage: "",
      });
    }, 5000);
  };

  setNewAgentSettings = async () => {
    this.setState({
      agentErrorMessage: "",
      agentSuccessMessage: "",
      agent_name: "",
      agent_email: "",
    });
    window.$("#modalAddNewAgent").modal("show");
  };

  modalHideDel = () => {
    window.$("#modalDeleteAlert").modal("hide");
  };

  render() {
    return (
      <div className="nk-content " id="Agents_Block">
        <div className="container-fluid">
          {/* MSA WARNING  START*/}
          {this.props.defaultSettings === "missing" &&
          this.props.from !== "dashboard" ? (
            <SettingsError />
          ) : null}
          {this.props.is_msa_signed === "No" &&
          this.props.from !== "dashboard" ? (
            <MsaWarning />
          ) : null}
          {/* MSA WARNING END */}
          <div className="nk-content-inner">
            <div class="nk-content-body">
              <div class="nk-block-head nk-block-head-sm">
                <div class="nk-block-between g-3">
                  <div class="nk-block-head-content">
                    <h3 class="nk-block-title page-title">
                      Channel Partner Commission /{" "}
                      <strong class="text-primary small">
                        {this.state.name}
                      </strong>
                    </h3>
                    <div class="nk-block-des text-soft">
                      <ul class="list-inline">
                        <li>
                          Company:{" "}
                          <span class="text-base">
                            {this.state.agent_company}
                          </span>
                        </li>
                        <li>
                          Title:{" "}
                          <span class="text-base">{this.state.title}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="nk-block-head-content">
                    <a
                      onClick={() => this.addMenuHistory("/agents")}
                      style={{ cursor: "pointer" }}
                      class="btn btn-outline-light bg-white d-none d-sm-inline-flex"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                      <span>Back</span>
                    </a>
                    <a
                      onClick={() => this.addMenuHistory("/agents")}
                      style={{ cursor: "pointer" }}
                      class="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                    >
                      <em class="icon ni ni-arrow-left"></em>
                    </a>
                  </div>
                </div>
              </div>
              <div class="nk-block">
                <div class="card card-bordered">
                  <div class="card-aside-wrap clientsView">
                    <div class="card-content">
                      <ul class="nav nav-tabs nav-tabs-mb-icon nav-tabs-card viewClient_nav-tabs_customStyle">
                        <li class="nav-item viewClients_nav-item_customStyle">
                          <a
                            style={{ cursor: "pointer" }}
                            className={`nav-link ${
                              this.state.activeTab === "statistics"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("statistics");
                              this.get_commission_statistics(
                                this.state.agentId
                              );
                            }}
                          >
                            <em class="icon ni ni-report-profit"></em>
                            <span>Statistics</span>
                          </a>
                        </li>
                        <li class="nav-item viewClients_nav-item_customStyle">
                          <a
                            style={{ cursor: "pointer" }}
                            className={`nav-link ${
                              this.state.activeTab === "commissionSettings"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("commissionSettings");
                              this.get_commission_settings(this.state.agentId);
                            }}
                          >
                            <em class="icon ni ni-setting"></em>
                            <span>Settings</span>
                          </a>
                        </li>
                        <li class="nav-item viewClients_nav-item_customStyle">
                          <a
                            style={{ cursor: "pointer" }}
                            className={`nav-link ${
                              this.state.activeTab === "commissionLogs"
                                ? "active"
                                : ""
                            }`}
                            onClick={(e) => {
                              this.changeActiveTab("commissionLogs");
                              this.get_commission_logs();
                            }}
                          >
                            <em class="icon ni ni-sign-mxn-alt"></em>
                            <span>Paid Commission Logs</span>
                          </a>
                        </li>
                      </ul>
                      <div class="tab-content">
                        <div
                          className={
                            this.state.activeTab === "commissionLogs"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemStatictics"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div className="col-lg-12 col-xxl-12">
                                {this.state.commission_logs_loader === true ? (
                                  FormLoader()
                                ) : (
                                  <>
                                    <div className="row">
                                      <div className="card-title">
                                        <h6 className="title">Commissions</h6>
                                      </div>
                                      <div className="col-lg-12 col-xxl-12">
                                        <DataTableExtended
                                          columns={
                                            this.state.columnCommissionLogs
                                          }
                                          tableData={this.state.commissionLogs}
                                          title=""
                                        />
                                      </div>
                                      <div className="card-tools"></div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.activeTab === "statistics"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemStatictics"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div className="col-lg-6 col-xxl-12">
                                <div className="row g-gs">
                                  <div className="col-sm-6 col-lg-12 col-xxl-6">
                                    <div className="card card-bordered">
                                      <div className="card-inner">
                                        {this.state.show_total_sales ===
                                        true ? (
                                          UpworkJobLoader()
                                        ) : (
                                          <>
                                            <div className="card-title-group align-start mb-2">
                                              <div className="card-title">
                                                <h6 className="title">
                                                  Total Sales
                                                </h6>
                                              </div>
                                              <div className="card-tools"></div>
                                            </div>
                                            <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                              <div className="nk-sale-data">
                                                <span className="amount">
                                                  {formatAsCurrency(
                                                    this.state.agent_total_sales
                                                  )}
                                                </span>
                                                <span className="sub-title">
                                                  <span className="text-primary">
                                                    Sales Report
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-6 col-lg-12 col-xxl-6">
                                    <div className="card card-bordered">
                                      <div className="card-inner">
                                        {this.state.show_total_commision ===
                                        true ? (
                                          UpworkJobLoader()
                                        ) : (
                                          <>
                                            <div className="card-title-group align-start mb-2">
                                              <div className="card-title">
                                                <h6 className="title">
                                                  Total Approved Commission
                                                </h6>
                                              </div>
                                              <div className="card-tools"></div>
                                            </div>
                                            <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                              <div className="nk-sale-data">
                                                <span className="amount">
                                                  {formatAsCurrency(
                                                    this.state
                                                      .agent_total_commision
                                                  )}
                                                </span>
                                                <span className="sub-title">
                                                  <span className="text-primary">
                                                    Commission Report
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-6 col-lg-12 col-xxl-6">
                                    <div className="card card-bordered">
                                      <div className="card-inner">
                                        {this.state.show_paid_commision ===
                                        true ? (
                                          UpworkJobLoader()
                                        ) : (
                                          <>
                                            <div className="card-title-group align-start mb-2">
                                              <div className="card-title">
                                                <h6 className="title">Paid</h6>
                                              </div>
                                              <div className="card-tools"></div>
                                            </div>
                                            <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                              <div className="nk-sale-data">
                                                <span className="amount">
                                                  {formatAsCurrency(
                                                    this.state
                                                      .agent_paid_commision
                                                  )}
                                                </span>
                                                <span className="sub-title">
                                                  <span className="text-primary">
                                                    Paid Report
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-6 col-lg-12 col-xxl-6">
                                    <div className="card card-bordered">
                                      <div className="card-inner">
                                        {this.state.show_pending_commision ===
                                        true ? (
                                          UpworkJobLoader()
                                        ) : (
                                          <>
                                            <div className="card-title-group align-start mb-2">
                                              <div className="card-title">
                                                <h6 className="title">
                                                  Pending
                                                </h6>
                                              </div>
                                              <div className="card-tools"></div>
                                            </div>
                                            <div className="align-end flex-sm-wrap g-4 flex-md-nowrap">
                                              <div className="nk-sale-data">
                                                <span className="amount">
                                                  {formatAsCurrency(
                                                    this.state
                                                      .agent_pending_commision
                                                  )}
                                                </span>
                                                <span className="sub-title">
                                                  <span className="text-primary">
                                                    {this.state
                                                      .agent_pending_commision &&
                                                    this.state
                                                      .agent_pending_commision >
                                                      0 ? (
                                                      <a
                                                        onClick={() =>
                                                          this.get_agent_orders()
                                                        }
                                                        className="text-primary"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        Pay Now
                                                      </a>
                                                    ) : (
                                                      <> </>
                                                    )}
                                                  </span>
                                                </span>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  {this.state.pay_now_form_block === true ? (
                                    FormLoader()
                                  ) : (
                                    <>
                                      {this.state.pay_now_form === true ? (
                                        <div
                                          className="col-md-12 col-lg-12"
                                          id="payForm"
                                        >
                                          <div class="card card-bordered mt-2">
                                            <div class="card-inner">
                                              <div class="row g-gs">
                                                {this.state.fundErrorMessage !==
                                                "" ? (
                                                  <div className="col-md-12 mb-2">
                                                    <div class="alert alert-pro alert-danger">
                                                      <div class="alert-text">
                                                        <h6>Error</h6>
                                                        <p>
                                                          {
                                                            this.state
                                                              .fundErrorMessage
                                                          }
                                                        </p>
                                                      </div>
                                                    </div>{" "}
                                                  </div>
                                                ) : null}
                                                {this.state
                                                  .fundSuccessMessage !== "" ? (
                                                  <div className="col-md-12 mb-2">
                                                    <div class="alert alert-pro alert-success">
                                                      <div class="alert-text">
                                                        <h6>Success</h6>
                                                        <p>
                                                          {
                                                            this.state
                                                              .fundSuccessMessage
                                                          }
                                                        </p>
                                                      </div>
                                                    </div>
                                                  </div>
                                                ) : null}
                                                <div class="col-md-12">
                                                  <h5>Pay Channel Partner </h5>

                                                  <p>
                                                    The process to pay a channel
                                                    partner involves entering
                                                    the payment amount,
                                                    specifying the payment date,
                                                    selecting a payment method
                                                    (such as QuickBooks, Bank
                                                    Account, Wire Transfer, or
                                                    Other), uploading a receipt
                                                    as proof of payment, and
                                                    optionally adding a short
                                                    note about the payment. Once
                                                    all details are provided,
                                                    the payment can be submitted
                                                    by clicking the "Pay"
                                                    button.
                                                  </p>
                                                </div>
                                                <div className="col-lg-6">
                                                  <div class="form-group">
                                                    <div class="form-label-group">
                                                      <label class="form-label">
                                                        <Tooltip
                                                          title="Paid orders listing whose commission is approved."
                                                          placement="right"
                                                        >
                                                          <em
                                                            className="icon ni ni-info"
                                                            style={{
                                                              color: "#6576ff",
                                                            }}
                                                          ></em>
                                                        </Tooltip>{" "}
                                                        Select an Order{" "}
                                                        <sup></sup>
                                                        <span class="text-danger"></span>
                                                      </label>
                                                    </div>
                                                    <div class="form-control-group">
                                                      <Select2
                                                        options={
                                                          this.state
                                                            .allAgentOrders
                                                        }
                                                        name="orderno"
                                                        placeholder="Select an order."
                                                        value={
                                                          this.state
                                                            .order_details
                                                        }
                                                        autoComplete="off"
                                                        emptyMessage="Order not found"
                                                        onChange={
                                                          this
                                                            .handleChangeSearch
                                                        }
                                                        isClearable
                                                        isSearchable
                                                        components={
                                                          animatedComponents
                                                        }
                                                        styles={{
                                                          control: (
                                                            baseStyles,
                                                            state
                                                          ) => ({
                                                            ...baseStyles,
                                                            lineHeight: "40px",
                                                          }),
                                                        }}
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                
                                                <div className="col-md-6">
                                                  <div className="form-group">
                                                    <div className="form-label-group">
                                                      <label className="form-label">
                                                        <Tooltip
                                                          title="Enter the amount to deposit to the channel partner account."
                                                          placement="right"
                                                        >
                                                          <em
                                                            className="icon ni ni-info"
                                                            style={{
                                                              color: "#6576ff",
                                                            }}
                                                          ></em>
                                                        </Tooltip>{" "}
                                                        Enter Amount{" "}
                                                        <sup>*</sup>
                                                        <span className="text-danger"></span>
                                                      </label>
                                                    </div>
                                                    <div className="form-control-group">
                                                      <MutextField
                                                        id="payment_amount"
                                                        name="payment_amount"
                                                        type="text"
                                                        label=""
                                                        value={
                                                          this.state
                                                            .payment_amount
                                                        }
                                                        onChange={
                                                          this.handleChange
                                                        }
                                                        onKeyPress={() => {
                                                          this.checkNumber();
                                                        }}
                                                        inputProps={{
                                                          maxLength: 9, // Set the maximum length to 10 characters
                                                        }}
                                                        variant="outlined"
                                                        fullWidth
                                                      />
                                                    </div>
                                                  </div>
                                                </div>

                                                <div class="col-md-6">
                                                  <div class="form-group">
                                                    <label class="form-label">
                                                      Payment Date
                                                    </label>
                                                    <div class="form-control-wrap">
                                                      <MuiPickersUtilsProvider
                                                        utils={DateFnsUtils}
                                                      >
                                                        <DateTimePicker
                                                          variant="dialog"
                                                          name="payment_date"
                                                          id="payment_date"
                                                          inputVariant="outlined"
                                                          format="dd/MM/yyyy HH:mm"
                                                          fullWidth
                                                          value={
                                                            this.state
                                                              .payment_date
                                                          }
                                                          onChange={
                                                            this
                                                              .handleDateChange
                                                          }
                                                          rientation="landscape"
                                                        />
                                                      </MuiPickersUtilsProvider>
                                                    </div>
                                                    <div class="form-note">
                                                      Date of payment received.
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="col-md-6">
                                                  <div class="form-group">
                                                    <label class="form-label">
                                                      Payment Method
                                                    </label>
                                                    <div class="form-control-wrap">
                                                      <select
                                                        class="form-control form-control-lg form-select-lg"
                                                        name="payment_method_pay"
                                                        id="payment_method_pay"
                                                        onChange={
                                                          this.handleChange
                                                        }
                                                      >
                                                        <option
                                                          value="Quickbooks"
                                                          key="aacc-1"
                                                        >
                                                          Quickbooks
                                                        </option>
                                                        <option
                                                          value="bank"
                                                          key="aacc-2"
                                                        >
                                                          Bank Account
                                                        </option>
                                                        <option
                                                          value="wire"
                                                          key="aacc-3"
                                                        >
                                                          Wire Transfer
                                                        </option>
                                                        <option
                                                          value="other"
                                                          key="aacc-33"
                                                        >
                                                          Other
                                                        </option>
                                                      </select>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-lg-6">
                                                  <div className="form-group">
                                                    <div className="form-label-group">
                                                      <label className="form-label">
                                                        <Tooltip
                                                          title="Receipt of the payment sent to the channel partner."
                                                          placement="right"
                                                        >
                                                          <em
                                                            className="icon ni ni-info"
                                                            style={{
                                                              color: "#6576ff",
                                                            }}
                                                          ></em>
                                                        </Tooltip>{" "}
                                                        Upload Receipt{" "}
                                                        <sup></sup>
                                                        <span className="text-danger"></span>
                                                      </label>
                                                    </div>
                                                    <div className="form-control-group signatory_upload">
                                                      <div class="custom-file">
                                                        <input
                                                          type="file"
                                                          class="custom-file-input"
                                                          id="add_picture"
                                                          name="add_picture"
                                                          defaultValue={
                                                            this.state
                                                              .add_picture
                                                          }
                                                          accept=".png, .jpg, .jpeg, .pdf"
                                                          onChange={
                                                            this
                                                              .handleFileChange
                                                          }
                                                        />
                                                        <label
                                                          class="custom-file-label"
                                                          for="add_picture"
                                                        >
                                                          Choose file
                                                        </label>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div class="col-md-12">
                                                  <div class="form-group">
                                                    <label
                                                      class="form-label"
                                                      for="payment_notes"
                                                    >
                                                      Payment Notes
                                                    </label>
                                                    <div class="form-control-wrap">
                                                      <input
                                                        type="text"
                                                        class="form-control form-control-lg"
                                                        id="payment_notes"
                                                        name="payment_notes"
                                                        onChange={
                                                          this.handleChange
                                                        }
                                                        defaultValue={
                                                          this.state
                                                            .payment_notes
                                                        }
                                                        placeholder="Add payment notes with a maximum of 100 characters.."
                                                        maxLength="100"
                                                        autoComplete="no"
                                                      />
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="col-md-12">
                                                  <div class="form-action mt-2 d-flex justify-content-end">
                                                    <ul class="form-btn-group">
                                                      {this.state
                                                        .add_funds_button ===
                                                      false ? (
                                                        <li class="">
                                                          <a
                                                            onClick={() => {
                                                              this.setState({
                                                                pay_now_form: false,
                                                                pay_now_button: false,
                                                                payment_amount:
                                                                  "",
                                                                fundErrorMessage:
                                                                  "",
                                                                fundSuccessMessage:
                                                                  "",
                                                              });
                                                            }}
                                                            class="btn btn-dim btn-outline-light"
                                                          >
                                                            Cancel
                                                          </a>
                                                        </li>
                                                      ) : null}
                                                      <li class="form-btn-primary">
                                                        {this.state
                                                          .add_funds_button ===
                                                        false ? (
                                                          <a
                                                            onClick={() =>
                                                              this.addFundsInAgentAccount()
                                                            }
                                                            class="btn btn-primary text-white"
                                                          >
                                                            <em class="icon ni ni-sign-dollar"></em>{" "}
                                                            Pay
                                                          </a>
                                                        ) : (
                                                          <button
                                                            className="btn btn-primary"
                                                            disabled
                                                          >
                                                            <em class="icon ni ni-sign-dollar"></em>{" "}
                                                            Pay
                                                            <div
                                                              className="spinner-grow spinner-grow-sm"
                                                              role="status"
                                                            >
                                                              <span className="sr-only">
                                                                Loading...
                                                              </span>
                                                            </div>
                                                          </button>
                                                        )}
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : null}
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div class="nk-divider divider md"></div>
                          </div>
                        </div>
                        <div
                          className={
                            this.state.activeTab === "commissionSettings"
                              ? "tab-pane active"
                              : "tab-pane"
                          }
                          id="tabItemSettings"
                        >
                          <div class="card-inner">
                            <div class="nk-block">
                              <div className="row g-gs">
                                <div className="col-lg-12 col-xxl-12 col-sm-12">
                                  <h4 className="title nk-block-title">
                                    Commission Settings
                                  </h4>
                                  {this.state.commissionErrorMessage !== "" ? (
                                    <div className="col-md-12">
                                      <div
                                        className="example-alert"
                                        style={{ marginBottom: "15px" }}
                                      >
                                        <div className="alert alert-pro alert-danger">
                                          <div className="alert-text">
                                            <h4>
                                              {this.state.commissionErrorTitle}
                                            </h4>
                                            <p>
                                              {
                                                this.state
                                                  .commissionErrorMessage
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                  {this.state.commissionSuccessMessage !==
                                  "" ? (
                                    <div className="col-md-12">
                                      <div
                                        className="example-alert"
                                        style={{ marginBottom: "15px" }}
                                      >
                                        <div className="alert alert-pro alert-success">
                                          <div className="alert-text">
                                            <h4>Success</h4>
                                            <p>
                                              {
                                                this.state
                                                  .commissionSuccessMessage
                                              }
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <div className="form-label-group">
                                      <label className="form-label">
                                        <Tooltip
                                          title="Set the default value of how the commission will be applied."
                                          placement="right"
                                        >
                                          <em
                                            className="icon ni ni-info"
                                            style={{
                                              color: "#6576ff",
                                            }}
                                          ></em>
                                        </Tooltip>{" "}
                                        Apply Commission{" "}
                                        <span className="text-danger"></span>
                                      </label>
                                    </div>

                                    <div className="form-control-wrap ">
                                      <div className="form-control-select">
                                        <select
                                          className="form-control form-control-lg"
                                          id="apply_commission"
                                          name="apply_commission"
                                          select
                                          label=""
                                          value={this.state.apply_commission}
                                          onChange={this.handleChange}
                                        >
                                          <option
                                            key="setac1"
                                            value="agent_account"
                                          >
                                            Channel Partner Account
                                          </option>
                                          <option
                                            key="setac2"
                                            value="product_services"
                                          >
                                            Product & Services
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xxl-6 col-sm-12">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="default-01"
                                    >
                                      Commission Value{" "}
                                      {this.state.commission_value_type ===
                                      "percentage"
                                        ? "(%)"
                                        : ""}
                                    </label>
                                    <div className="form-control-wrap">
                                      <input
                                        type="text"
                                        className="form-control form-control-lg"
                                        id="commission_value"
                                        name="commission_value"
                                        label=""
                                        value={this.state.commission_value}
                                        onChange={this.handleChange}
                                        onKeyPress={() => {
                                          this.checkNumber();
                                        }}
                                        maxLength="9"
                                        //inputProps={{ maxLength: 9 }}
                                      />
                                    </div>
                                    <div className="form-note"></div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xxl-6 col-sm-12">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="commission_type"
                                    >
                                      <Tooltip
                                        title="Billed Commission: Paid when the sale is invoiced or billed to the customer, regardless of whether the payment has been received. Collected Commission: Paid only when the payment from the customer is collected. This can be more common in industries where payment collection might be a prolonged process."
                                        placement="right"
                                      >
                                        <em
                                          className="icon ni ni-info"
                                          style={{
                                            color: "#6576ff",
                                          }}
                                        ></em>
                                      </Tooltip>{" "}
                                      Commission Payment Terms
                                    </label>
                                    <div className="form-control-wrap ">
                                      <div className="form-control-select">
                                        <select
                                          className="form-control form-control-lg"
                                          id="commission_type"
                                          name="commission_type"
                                          select
                                          label="Choose Payment Terms"
                                          value={this.state.commission_type}
                                          onChange={this.handleChange}
                                        >
                                          <option
                                            key="setst1"
                                            value="paid_on_billed"
                                          >
                                            Paid On Billed
                                          </option>
                                          <option
                                            key="setst2"
                                            value="paid_on_collected"
                                          >
                                            Paid On Collected
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="form-note"></div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xxl-6 col-sm-12">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="commission_value_type"
                                    >
                                      Commission Type
                                    </label>
                                    <div className="form-control-wrap ">
                                      <div className="form-control-select">
                                        <select
                                          className="form-control form-control-lg"
                                          id="commission_value_type"
                                          name="commission_value_type"
                                          select
                                          label="Choose Commission Type"
                                          value={
                                            this.state.commission_value_type
                                          }
                                          onChange={this.handleChange}
                                        >
                                          <option key="setst33" value="fixed">
                                            Fixed
                                          </option>
                                          <option
                                            key="setst23"
                                            value="percentage"
                                          >
                                            Percentage
                                          </option>
                                        </select>
                                      </div>
                                    </div>
                                    <div className="form-note">
                                      Channel partner receive a fixed or
                                      percentage based on the profit margin.
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6 col-xxl-6 col-sm-12">
                                  <div className="form-group">
                                    <label
                                      className="form-label"
                                      htmlFor="default-01"
                                    >
                                      Withdraw Threshold (USD)
                                    </label>
                                    <div className="form-control-wrap">
                                      <input
                                        type="text"
                                        className="form-control form-control-lg"
                                        id="draw_threshold"
                                        name="draw_threshold"
                                        label=""
                                        value={this.state.draw_threshold}
                                        onChange={this.handleChange}
                                        onKeyPress={() => {
                                          this.checkNumber();
                                        }}
                                        maxLength="9"
                                        //inputProps={{ maxLength: 9 }}
                                      />
                                    </div>
                                    <div className="form-note">
                                      Monthly Withdrawal Limit: A channel
                                      partner cannot withdraw more than this
                                      specified amount within a month.
                                    </div>
                                    <div className="form-note"></div>
                                  </div>
                                </div>
                                <div className="col-lg-12 col-xxl-12 col-sm-12 mt-4  text-right">
                                  <div className="form-group">
                                    {this.state.commission_button === false ? (
                                      <button
                                        type="button"
                                        className="btn btn-lg btn-primary"
                                        onClick={() =>
                                          this.update_commission_settings()
                                        }
                                        disabled={this.state.disabled}
                                      >
                                        Save Settings
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-lg btn-primary"
                                        style={{ marginRight: "15px" }}
                                        disabled
                                      >
                                        <em className="icon ni ni-swap"></em>{" "}
                                        Save Settings
                                        <div
                                          className="spinner-grow spinner-grow-sm"
                                          role="status"
                                        >
                                          <span className="sr-only">
                                            Loading...
                                          </span>
                                        </div>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="nk-divider divider md"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="card-aside card-aside-right user-aside"
                      data-content="userAside"
                      data-toggle-screen="xxl"
                      data-toggle-overlay="true"
                      data-toggle-body="true"
                    >
                      <div class="card-inner-group" data-simplebar>
                        <div class="card-inner">
                          <div class="user-card user-card-s2">
                            <div class="user-avatar lg bg-primary">
                              <span>CP</span>
                            </div>
                            <div class="user-info">
                              {this.state.agent_status &&
                              this.state.agent_status === "1" ? (
                                <div class="badge badge-outline-success badge-pill ucap">
                                  Active
                                </div>
                              ) : (
                                <div class="badge badge-outline-danger badge-pill ucap">
                                  Inactive
                                </div>
                              )}
                              <h5>{this.state.name}</h5>
                              <span class="sub-text">
                                {this.state.agent_email}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/*<div class="card-inner card-inner-sm">
                          <ul class="btn-toolbar justify-center gx-1">
                            <li>
                              <a href="#" class="btn btn-trigger btn-icon">
                                <em class="icon ni ni-shield-off"></em>
                              </a>
                            </li>
                            <li>
                              <a href="#" class="btn btn-trigger btn-icon">
                                <em class="icon ni ni-mail"></em>
                              </a>
                            </li>
                            <li>
                              <a href="#" class="btn btn-trigger btn-icon">
                                <em class="icon ni ni-download-cloud"></em>
                              </a>
                            </li>
                            <li>
                              <a href="#" class="btn btn-trigger btn-icon">
                                <em class="icon ni ni-bookmark"></em>
                              </a>
                            </li>
                            <li>
                              <a
                                href="#"
                                class="btn btn-trigger btn-icon text-danger"
                              >
                                <em class="icon ni ni-na"></em>
                              </a>
                            </li>
                          </ul>
                        </div>*/}
                        <div class="card-inner">
                          <div class="overline-title-alt mb-2">In Account</div>
                          <div class="profile-balance">
                            <div class="profile-balance-group gx-4">
                              <div class="profile-balance-sub">
                                <div class="profile-balance-amount">
                                  <div class="number">
                                    {formatAsCurrency(
                                      this.state.agent_total_sales
                                    )}{" "}
                                  </div>
                                </div>
                                <div class="profile-balance-subtitle">
                                  Total Sales
                                </div>
                              </div>
                              <div class="profile-balance-sub">
                                <span class="profile-balance-plus text-soft">
                                  <em class="icon ni ni-plus"></em>
                                </span>
                                <div class="profile-balance-amount">
                                  <div class="number">
                                    {formatAsCurrency(
                                      this.state.agent_paid_commision
                                    )}
                                  </div>
                                </div>
                                <div class="profile-balance-subtitle">
                                  Total Paid
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/*<div class="card-inner">
                          <div class="row text-center">
                            <div class="col-4">
                              <div class="profile-stats">
                                <span class="amount">23</span>
                                <span class="sub-text">Total Orders</span>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="profile-stats">
                                <span class="amount">20</span>
                                <span class="sub-text">Complete</span>
                              </div>
                            </div>
                            <div class="col-4">
                              <div class="profile-stats">
                                <span class="amount">3</span>
                                <span class="sub-text">Progress</span>
                              </div>
                            </div>
                          </div>
                        </div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* View Logs */}
          <div
            className="modal fade zoom"
            tabIndex="-1"
            id="modalCommissionLogs"
          >
            <div
              className="modal-dialog modal-xl modal-dialog-top"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <div
                    className="modal-title"
                    style={{ textAlign: "center", fontSize: "20px" }}
                  >
                    <strong>
                      <em className="icon ni ni-list-round"></em> Payment &
                      Other Details{" "}
                    </strong>
                  </div>
                  <a
                    href="#"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <em className="icon ni ni-cross"></em>
                  </a>
                </div>

                <div className="modal-body">
                  {/* NRC DIVS START */}

                  {this.state.viewLoader === true ? (
                    FormLoader()
                  ) : (
                    <>
                      <div>
                        <div className="card card-bordered">
                          <div className="card-inner-group">
                            <div className="card-inner">
                              {this.state.view_payment_details &&
                              this.state.view_payment_details.length > 0 ? (
                                <div className="sp-plan-head">
                                  <h6 className="title">
                                    Form Submission Details
                                  </h6>
                                </div>
                              ) : null}
                              <div className="sp-plan-desc sp-plan-desc-mb">
                                {this.state.view_payment_details &&
                                this.state.view_payment_details.length > 0 ? (
                                  <table class="table table-bordered table-striped">
                                    <thead>
                                      <tr>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Payment Method</th>
                                        <th scope="col">Receipt</th>
                                        <th scope="col">Payment Notes</th>
                                        {/*<th scope="col">Action</th>*/}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {this.state.view_payment_details.map(
                                        (general, index) => (
                                          <tr key={`idxx${index}`}>
                                            <td>
                                              {formatAsCurrency(
                                                general.commission_amount
                                              )}
                                            </td>
                                            <td>
                                              {this.format_date(
                                                general.dateAdded
                                              )}
                                            </td>
                                            <td>
                                              {general.payment_method_pay &&
                                              general.payment_method_pay ===
                                                "bank"
                                                ? "Bank transfer"
                                                : general.payment_method_pay &&
                                                  general.payment_method_pay ===
                                                    "wire"
                                                ? "Wire Transfer"
                                                : general.payment_method_pay &&
                                                  general.payment_method_pay ===
                                                    "other"
                                                ? "Other"
                                                : general.payment_method_pay &&
                                                  general.payment_method_pay ===
                                                    "Quickbooks"
                                                ? "Quickbooks"
                                                : "N/A"}
                                            </td>
                                            <td>
                                              {general.payment_receipt &&
                                              general.payment_receipt !== "" ? (
                                                <a
                                                  style={{
                                                    cursor: "pointer",
                                                  }}
                                                  onClick={() =>
                                                    this.downloadReceipt(
                                                      general.payment_receipt
                                                    )
                                                  }
                                                >
                                                  <span class="badge badge-outline-dark">
                                                    View
                                                  </span>
                                                </a>
                                              ) : (
                                                "---"
                                              )}
                                            </td>
                                            <td>{general.payment_notes}</td>
                                            {/*<td>{general.id}</td>*/}
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                ) : null}
                                <div className="sp-plan-head mt-3 mb-1">
                                  <h6 className="title">Other Details</h6>
                                </div>
                                <table class="table table-bordered table-striped">
                                  <thead>
                                    <tr>
                                      <th scope="col">
                                        Commission Payment Terms
                                      </th>
                                      <th scope="col">Commission Value (%)</th>
                                      <th scope="col">Commission Type</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>{this.state.view_commission_type}</td>
                                      <td>
                                        {this.state.view_commission_value}
                                      </td>
                                      <td>
                                        {this.state.view_commission_value_type}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {/* View Logs */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Agents);
