import { React } from "react";
import { Component } from "react";

import { COMPANY_NAME } from "../../../config/config";
import axios from "axios";
import { connect } from "react-redux";

import { addSettings } from "../../../actions";

import {
  getReportsData,
  getCommissionReports,
  listAgentsSuper,
  listClientsSuper,
  listCompleteOrders,
  getBillingDetails,
  getAgentRequests,
  insertSalesorder,
} from "./../../../config/api_calls";

import {
  dateString,
  APP_URL,
  AGENT_SERVER_URL,
  APP_LIVE_URL,
} from "./../../../config/config";

import $ from "jquery";

import DataTable from "../../../components/Tables/DataTableReports";
import { tableLoader } from "../../../components/ContentLoaders/table_loader";
import { Link } from "react-router-dom";
import { FormLoader } from "../../../components/ContentLoaders/newLoaders/Form";
import MsaWarning from "../ExtraComponents/MsaWarning";
import SettingsError from "../ExtraComponents/SettingsError";
import PageTitle from "../ExtraComponents/PageTitle";
import HelperClass from "./../../../config/helperClass";
import Tooltip from "@material-ui/core/Tooltip";

import dateFormat, { masks } from "dateformat";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

import TextField from "../../../components/InputFields/TextField";
import MutextField from "@material-ui/core/TextField";
import * as XLSX from "xlsx";
import moment from "moment";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import {
  THEME_TEXT_COLOR,
  THEME_COLOR,
  columnsServices,
} from "./../../../config/config";
import DateRangePicker from "react-daterange-picker";
import "react-daterange-picker/dist/css/react-calendar.css";
import { extendMoment } from "moment-range";
import Select from "react-select";
import makeAnimated from "react-select/animated";
const extendedMoment = extendMoment(moment);

const animatedComponents = makeAnimated();

const auth = new HelperClass();

class Reports extends Component {
  constructor() {
    var date = (date = new Date()),
      y = date.getFullYear(),
      m = date.getMonth();

    var datestring = new Date().toISOString().slice(0, 10);

    super();
    this.decimalPoint = Number(auth.getDecimalPoint());
    const today = extendedMoment();
    const monthAgo = moment().subtract(1, "month");
    this.state = {
      tableData: [],
      tableLoader: true,
      //start_date: monthAgo.format("YYYY-MM-DD"),
      //end_date: today.format("YYYY-MM-DD"),
      start_date: "",
      end_date: "",
      selected_order_accountno: null,
      selected_client_accountno: null,
      selected_agent_accountno: null,
      value: moment.range(monthAgo, today),
      commission_title_array: "get_commission_reports",
      commissionReportsArray: [],
      all_active_agents: [],
      all_active_clients: [],
      all_complete_orders: [],
      deposit_main_section: false,
      depositErrorMessage: "",
      depositSuccessMessage: "",
      emailto: "",
      emailcc: "",
      emailbcc: "",
      emailsubject: "",
      emailcontent: "",
      email_button: false,
      emailErrorMessage: "",
      emailErrorMessageTitle: "",
      emailSuccessMessage: "",
      orderno: "",
      form_title: "",
      formDetails: [],
      formGroups: [],
      is_submitted: "",
      payment_method_title: "",
      selected_payment_method: "",
      paymentDetails: [],
      requestDetails: [],
      request_main_section: true,
      requestErrorMessage: "",
      requestErrorMessageTitle: "",
      commission_amount: 0,
      payment_amount: (0).toFixed(this.decimalPoint),
      payment_receipt: "",
      payment_notes: "",
      payment_date: new Date(),
      payment_method_pay: "Quickbooks",
      add_picture: "",
      add_picture_name: "",
      fundErrorMessage: "",
      fundSuccessMessage: "",
      add_funds_button: false,
      agent_pending_commision: 0,
      agent_paid_commision: 0,

      successMessage: "",
      errorMessage: "",
      document_file: "",
      csvData: [],

      columnsArray: [
        {
          name: "order_created_date",
          label: "Order Date",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.format_date(value)}</div>;
            },
          },
        },
        {
          name: "orderno",
          label: "Order #",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatOrderno(value)}</div>;
            },
          },
        },
        {
          name: "agent_details",
          label: "Channel Partner",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatCommission(value)}</div>;
            },
          },
        },

        {
          name: "client_details",
          label: "Client",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatCommission(value)}</div>;
            },
          },
        },
        {
          name: "order_total",
          label: "Order Total",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatAsCurrency(value)}</div>;
            },
          },
        },
        {
          name: "total_payments",
          label: "Order Total Paid",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatAsCurrency(value)}</div>;
            },
          },
        },
        {
          name: "payment_status",
          label: "Order Payment",
        },
        {
          name: "order_commission",
          label: "Commission",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatAsCurrency(value)}</div>;
            },
          },
        },
        {
          name: "commission_approved",
          label: "Commission Approved",
        },
        {
          name: "total_paid_commissions",
          label: "Paid Commission",
          options: {
            customBodyRender: (value, tableMeta, updateValue) => {
              return <div>{this.formatAsCurrency(value)}</div>;
            },
          },
        },
        {
          name: "commission_paid",
          label: "Commission Paid",
        },
        {
          name: "orderno",
          label: "Actions",
          options: {
            customBodyRender: (value, tableMeta) => {
              let edit_url = "/ViewSalesOrder/" + tableMeta.rowData[0];

              return (
                <div>
                  <div
                    className="nk-tb-col nk-tb-col-tools"
                    style={{ padding: "0px" }}
                  >
                    <ul className="nk-tb-actions gx-1">
                      <li>
                        <div className="drodown">
                          <a
                            href="#"
                            className="dropdown-toggle btn btn-icon btn-outline-light"
                            data-toggle="dropdown"
                          >
                            <em className="icon ni ni-more-h"></em>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right">
                            <ul className="link-list-opt no-bdr">
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.open_deposit_request(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Submit Deposit Request"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em class="icon ni ni-card-view"></em>
                                  <span>Add Payment</span>
                                </a>
                              </li>
                              <li>
                                <a
                                  data-toggle="tooltip"
                                  data-placement="left"
                                  onClick={() =>
                                    this.open_agent_requests(value, tableMeta)
                                  }
                                  disabled={
                                    this.props.is_msa_signed === "No" ||
                                    this.props.defaultSettings === "missing"
                                      ? true
                                      : false
                                  }
                                  title="Submit Deposit Request"
                                  style={{ cursor: "pointer" }}
                                >
                                  <em class="icon ni ni-card-view"></em>
                                  <span>CP Payment Requests</span>
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              );
            },
          },
        },
      ],
    };
  }

  serviceSigning = async (value, tableMeta) => {
    console.log("tableMeta.rowData: ", tableMeta.rowData[6]);
  };

  async componentDidMount() {
    await this.getActiveAgents();
    await this.getActiveClients();
    await this.getCompleteOrders();
    const reportsData = await getCommissionReports(
      auth.getAccount(),
      auth.getToken(),
      this.state.start_date,
      this.state.end_date,
      this.state.selected_order_accountno,
      this.state.selected_client_accountno,
      this.state.selected_agent_accountno
    );
    console.log("getCommissionReports: ", reportsData.data);
    console.log("start_date: ", this.state.start_date);
    console.log("end_date: ", this.state.end_date);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (reportsData.data.status === 404) {
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
      });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({
        commissionReportsArray: reportsData.data.data,
        tableLoader: false,
      });
    } else {
      console.log("Last: ", reportsData.data);
      //window.location.replace("/error");
    }
    $("#viewBtn").show();
    $("#btnloader").hide();
  }

  format_date = (value) => {
    let date = value;
    if (date && date !== "") {
      return dateFormat(date, "mmmm dS, yyyy");
    } else {
      return "---";
    }
  };

  formatOrderno = (value) => {
    return (
      <a
        href={`${APP_URL}ViewSalesOrder/${value}`}
        className="text-primary"
        target="_blank"
      >
        {value}
      </a>
    );
  };

  formatCommission = (value) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: value,
        }}
      />
    );
  };

  onSelect = (value) => {
    this.setState({
      value,
      start_date: value.start.format("YYYY-MM-DD"),
      end_date: value.end.format("YYYY-MM-DD"),
      isOpen: false,
    });
  };

  onToggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  regenerate_report = async () => {
    const {
      start_date,
      end_date,
      selected_order_accountno,
      selected_client_accountno,
      selected_agent_accountno,
    } = this.state;
    $("#viewBtn").hide();
    $("#btnloader").show();
    this.setState({ tableLoader: true });
    const reportsData = await getCommissionReports(
      auth.getAccount(),
      auth.getToken(),
      start_date,
      end_date,
      selected_order_accountno,
      selected_client_accountno,
      selected_agent_accountno
    );
    console.log("getCommissionReports regenerate_report: ", reportsData.data);
    console.log("start_date: ", start_date);
    console.log("end_date: ", end_date);
    if (
      reportsData.data.status === 403 ||
      reportsData.data.errors === "authentication missing" ||
      reportsData.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (reportsData.data.status === 404) {
      //window.location.replace("/error");
      $("#viewBtn").show();
      $("#btnloader").hide();
      this.setState({
        tableLoader: false,
      });
    } else if (
      reportsData.data.status === 200 &&
      reportsData.data.message === "success"
    ) {
      this.setState({
        commissionReportsArray: reportsData.data.data,
        tableLoader: false,
      });
      $("#viewBtn").show();
      $("#btnloader").hide();
    } else {
      console.log("Last: ", reportsData.data);
      //window.location.replace("/error");
      this.setState({
        tableLoader: false,
      });
      $("#viewBtn").show();
      $("#btnloader").hide();
    }
  };

  open_agent_requests = async (value, tableMeta) => {
    this.setState({
      requestErrorMessage: "",
      requestErrorMessageTitle: "",
      request_main_section: true,
    });
    let orderno = tableMeta.rowData[1];
    window.$("#modalRequests").modal("show");
    const servicesResponce = await getAgentRequests(
      auth.getAccount(),
      auth.getToken(),
      orderno
    );

    console.log("getAgentRequests: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        requestDetails: [],
        request_main_section: false,
        requestErrorMessage: "Payment requests not found at the moment.",
        requestErrorMessageTitle: "No Records",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      this.setState({
        request_main_section: false,
        requestDetails: servicesResponce.data.data,
        requestErrorMessage: "",
        requestErrorMessageTitle: "",
      });
    } else {
      this.setState({
        requestDetails: [],
        request_main_section: false,
        requestErrorMessage: "Payment requests not found at the moment.",
        requestErrorMessageTitle: "No Records",
      });
    }
  };

  open_deposit_request = async (value, tableMeta) => {
    console.log("open_deposit_request: ", tableMeta.rowData);
    let order_paid = tableMeta.rowData[6];
    let commission_amount = Number(tableMeta.rowData[7]).toFixed(
      this.decimalPoint
    );
    let agent_pending_commision = Number(tableMeta.rowData[9]).toFixed(
      this.decimalPoint
    );
    let commission_paid = tableMeta.rowData[10];
    let orderno = tableMeta.rowData[1];
    let pending_amount = commission_amount - agent_pending_commision;
    console.log("pending_amount: ", pending_amount);
    this.setState({
      deposit_main_section: false,
      depositErrorMessage: "",
      depositSuccessMessage: "",
      email_button: false,
      emailErrorMessageTitle: "",
      orderno: orderno,
      payment_method_title: "",
      selected_payment_method: "",
      paymentDetails: [],
      commission_amount: commission_amount,
      agent_pending_commision: pending_amount,
      payment_amount: pending_amount.toFixed(this.decimalPoint),
      add_picture: "",
      add_picture_name: "",
      fundErrorMessage: "",
      fundSuccessMessage: "",
      add_funds_button: false,
    });
    if (order_paid !== "paid") {
      this.setState({
        deposit_main_section: false,
        depositErrorMessage:
          "You cannot add payment because the order has not been paid.",
        emailErrorMessageTitle: "Order Not Paid",
      });
    } else if (order_paid === "paid" && commission_paid === "Yes") {
      this.setState({
        deposit_main_section: false,
        depositErrorMessage: "Commission for this order has already been paid.",
        emailErrorMessageTitle: "Commission Paid",
      });
    } else if (order_paid === "paid" && commission_paid === "No") {
      await this.getBillingDetails(orderno);
      this.setState({
        deposit_main_section: true,
        depositErrorMessage: "",
        emailErrorMessageTitle: "",
      });
    }
    window.$("#modalDeposit").modal("show");
  };

  modalHideMarketingPDF = (id) => {
    window.$("#" + id).modal("hide");
  };

  fieldValidation = () => {
    let { emailto, emailsubject, emailcontent } = this.state;

    /*if (emailsubject === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        depositErrorMessage: "Subject is required.",
      });
      return false;
    } else*/ if (emailcontent === "") {
      this.setState({
        emailErrorMessageTitle: "Missing Fields",
        depositErrorMessage: "Notes are required.",
      });
      return false;
    } else {
      this.setState({
        emailErrorMessageTitle: "",
        depositErrorMessage: "",
      });
    }
    return true;
  };

  getBillingDetails = async (orderno) => {
    //const { orderno } = this.state;
    console.log("orderno: ", orderno);
    const servicesResponce = await getBillingDetails(
      auth.getAccount(),
      auth.getToken(),
      orderno
    );

    console.log("getBillingDetails: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        form_title: "",
        formDetails: [],
        is_submitted: "No",
      });
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let is_submitted = "No";
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        console.log(
          "servicesResponce.data.data[0].is_submitted: ",
          servicesResponce.data.data[0].is_submitted
        );
        is_submitted =
          servicesResponce.data.data[0].is_submitted === 1 ? "Yes" : "No";
      }
      console.log("is_submitted: ", is_submitted);
      this.setState({
        form_title: servicesResponce.data.form_title,
        formDetails: servicesResponce.data.data,
        formGroups: JSON.parse(servicesResponce.data.groups),
        is_submitted: is_submitted,
      });
    } else {
      this.setState({
        form_title: "",
        formDetails: [],
        is_submitted: "No",
      });
    }
  };

  addFundsInAgentAccount = async () => {
    console.log("Here");
    const {
      payment_date,
      selected_payment_method,
      payment_notes,
      add_picture,
      add_picture_name,
      orderno,
      payment_method_title,
    } = this.state;
    const modalBody = document.getElementById("modalDeposit");
    if (modalBody) {
      setTimeout(() => {
        modalBody.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 0);
    }
    this.setState({
      add_funds_button: true,
      fundErrorMessage: "",
      fundSuccessMessage: "",
    });
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Use "smooth" for a smooth scroll effect
    });
    let payment_amount = Number(this.state.payment_amount);
    let agent_pending_commision = Number(this.state.agent_pending_commision);
    let remaining_commission = 0;
    if (orderno && orderno !== "") {
      remaining_commission = agent_pending_commision;
    }
    console.log(
      "remaining_commission: " +
        typeof payment_amount +
        " - " +
        typeof remaining_commission +
        " - " +
        typeof agent_pending_commision
    );
    if (payment_amount <= 0) {
      this.setState({
        fundErrorMessage: "Amount should be greater than 0.",
        add_funds_button: false,
      });
    } else if (payment_amount > agent_pending_commision) {
      this.setState({
        fundErrorMessage:
          "Amount should be less than or equal to pending commission amount.",
        add_funds_button: false,
      });
    } else if (
      orderno &&
      orderno !== "" &&
      payment_amount > remaining_commission
    ) {
      this.setState({
        fundErrorMessage:
          "Amount should be less than or equal to order pending commission which is $ (" +
          Number(remaining_commission).toFixed(this.decimalPoint) +
          ").",
        add_funds_button: false,
      });
    } else {
      let paymentDetails = document.getElementById(
        "paymentMethodDetails"
      ).innerHTML;
      const formData = new FormData();
      formData.append("token", process.env.REACT_APP_API_TOKEN);
      formData.append("api_accountno", process.env.REACT_APP_API_ACCOUNTNO);
      formData.append("accountno", auth.getAccount());
      formData.append("file", add_picture);
      formData.append("fileName", add_picture_name);
      formData.append("payment_date", payment_date);
      formData.append("payment_method_pay", selected_payment_method);
      formData.append("payment_notes", payment_notes);
      formData.append("orderno", orderno);
      formData.append("paymentDetails", paymentDetails);
      formData.append("payment_amount", payment_amount);
      formData.append("payment_method_title", payment_method_title);

      try {
        axios
          .post(
            AGENT_SERVER_URL +
              "resellers/add_funds_in_agents_account_for_order",
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                "Access-Control-Allow-Origin": "http://localhost:3000",
                "Access-Control-Allow-Credentials": true,
              },
            },
            {}
          )
          .then((response) => {
            console.log("submitSignatory response.data : ", response.data);
            if (response.data.status === 404) {
              this.setState({
                fundErrorMessage:
                  "There is some error while adding the payment. Please try again.",
                add_funds_button: false,
              });
            } else if (response.data.status === 403) {
              this.setState({
                fundErrorMessage:
                  "There is some error while adding the payment. Please try again.",
                add_funds_button: false,
              });
            } else if (
              response.data.status === 200 &&
              response.data.message === "success"
            ) {
              this.setState({
                fundErrorMessage: "",
                fundSuccessMessage:
                  "Payment added successfully to the channel partner account. Please wait while we are closing the popup.",

                //add_funds_button: false,
              });

              setTimeout(async () => {
                this.setState({
                  fundErrorMessage: "",
                  fundSuccessMessage: "",
                  add_funds_button: false,
                });
                await this.regenerate_report();
                window.$("#modalDeposit").modal("hide");
              }, 4000);
            } else {
              this.setState({
                fundErrorMessage:
                  "There is some error while adding the payment. Please try again.",
                add_funds_button: false,
              });
            }
          });
      } catch (error) {
        this.setState({
          fundErrorMessage:
            "There is some error while adding the funds. Please try again.",
          add_funds_button: false,
        });

        console.log("catch : ", error);
      }
    }
    setTimeout(() => {
      this.setState({
        fundErrorMessage: "",
        fundSuccessMessage: "",
        add_funds_button: false,
      });
    }, 5000);
  };

  getCommissionDifference = async (orderNo) => {
    // Find the order with the matching orderno
    //console.log("orderNo: ", orderNo);
    const { agentOrdersData } = this.state;
    //console.log("agentOrdersData: ", agentOrdersData);
    const order = agentOrdersData.find((order) => order.orderno === orderNo);
    ///console.log("order After: ", order);
    // If order is found, calculate and return the difference
    if (order) {
      return Number(
        order.order_commission - order.total_paid_commission
      ).toFixed(this.decimalPoint);
    } else {
      // If no order is found, return null or an appropriate message
      return (0).toFixed(this.decimalPoint);
    }
  };

  getActiveAgents = async () => {
    const servicesResponce = await listAgentsSuper(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("getActiveAgents: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        signupLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          if (servicesResponce.data.data[i][5] === "active__Yes") {
            let newArr = {
              label:
                servicesResponce.data.data[i][0] +
                " (" +
                servicesResponce.data.data[i][1] +
                ")",
              value: servicesResponce.data.data[i][4],
            };
            all_clients.push(newArr);
          }
        }
      }
      this.setState({
        all_active_agents: all_clients,
        signupLoader: false,
      });
    } else {
      this.setState({
        signupLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  getActiveClients = async () => {
    const servicesResponce = await listClientsSuper(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("getActiveClients: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        signupLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          if (servicesResponce.data.data[i][5] === "active__Yes") {
            let newArr = {
              label:
                servicesResponce.data.data[i][0] +
                " (" +
                servicesResponce.data.data[i][1] +
                ")",
              value: servicesResponce.data.data[i][4],
              agent_accountno: servicesResponce.data.data[i][6],
            };
            all_clients.push(newArr);
          }
        }
      }
      this.setState({
        all_active_clients: all_clients,
        filtered_clients: all_clients,
        signupLoader: false,
      });
    } else {
      this.setState({
        signupLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  getCompleteOrders = async () => {
    const servicesResponce = await listCompleteOrders(
      auth.getAccount(),
      auth.getToken()
    );

    console.log("getCompleteOrders: ", servicesResponce.data);

    if (
      servicesResponce.data.status === 403 ||
      servicesResponce.data.errors === "authentication missing" ||
      servicesResponce.data.errors === "jwt expired"
    ) {
      auth.logout();
    } else if (servicesResponce.data.status === 404) {
      this.setState({
        signupLoader: false,
      });
      //window.location.replace("/error");
    } else if (
      servicesResponce.data.status === 200 &&
      servicesResponce.data.message === "success"
    ) {
      let all_clients = [];
      if (servicesResponce.data.data && servicesResponce.data.data.length > 0) {
        for (let i = 0; i < servicesResponce.data.data.length; i++) {
          let newArr = {
            label:
              servicesResponce.data.data[i][0] +
              " - " +
              this.formatAsCurrency(servicesResponce.data.data[i][2]) +
              " - " +
              this.format_date(servicesResponce.data.data[i][4]),
            value: servicesResponce.data.data[i][0],
            client_accountno: servicesResponce.data.data[i][1],
            agent_accountno: servicesResponce.data.data[i][3],
          };
          all_clients.push(newArr);
        }
      }
      this.setState({
        all_complete_orders: all_clients,
        filtered_orders: all_clients,
        signupLoader: false,
      });
    } else {
      this.setState({
        signupLoader: false,
      });
      // window.location.replace("/error");
    }
  };

  handleChangeSearch = async (changed_value) => {
    console.log("handleChangeSearch: ", changed_value);
    if (changed_value) {
      const selected_agent_accountno = changed_value.value;
      this.setState(
        (prevState) => ({
          selected_agent_accountno,
          selected_agent: changed_value,
          selected_client: null,
          selected_order: null,
          filtered_clients: prevState.all_active_clients.filter(
            (client) => client.agent_accountno === selected_agent_accountno
          ),
          filtered_orders: prevState.all_complete_orders.filter(
            (order) => order.agent_accountno === selected_agent_accountno
          ),
        }),
        () => {
          console.log(
            "Updated agent state:",
            this.state.selected_agent_accountno
          );
          console.log("Client selection reset.");
        }
      );
    } else {
      this.setState({
        selected_agent: null,
        selected_client: null,
        selected_order: null,
        selected_agent_accountno: null,
        filtered_clients: this.state.all_active_clients,
        filtered_orders: this.state.all_complete_orders,
      });
    }
  };

  handleChangeSearchClient = async (changed_value) => {
    console.log("handleChangeSearchClient: ", changed_value);
    if (changed_value) {
      const selected_client_accountno = changed_value.value;
      this.setState(
        (prevState) => ({
          selected_client_accountno: changed_value.value,
          selected_client: changed_value,
          selected_order: null,
          filtered_orders: prevState.all_complete_orders.filter(
            (order) =>
              order.agent_accountno === prevState.selected_agent_accountno &&
              order.client_accountno === selected_client_accountno
          ),
        }),
        () => {
          console.log(
            "Updated client state:",
            this.state.selected_client_accountno
          );
        }
      );
    } else {
      this.setState({
        selected_client: null,
        selected_order: null,
        selected_client_accountno: null,
        filtered_orders: this.state.all_complete_orders.filter(
          (order) =>
            order.agent_accountno === this.state.selected_agent_accountno
        ),
      });
    }
  };

  handleChangeSearchOrders = async (changed_value) => {
    console.log("handleChangeSearchOrders: ", changed_value);
    if (changed_value) {
      this.setState(
        (prevState) => ({
          selected_order_accountno: changed_value.value,
          selected_order: changed_value,
        }),
        () => {
          console.log(
            "Updated order state:",
            this.state.selected_order_accountno
          );
        }
      );
    } else {
      this.setState({ selected_order: null, selected_order_accountno: null });
    }
  };

  handleChange = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    console.log("handleChange " + name + " " + value);
    this.setState({
      [name]: value,
    });
  };

  handleDateChange = async (date) => {
    await this.handleStatesDynamic(
      "start_date",
      date.toISOString().slice(0, 10)
    );
    console.log("start_date " + this.state.start_date);
  };

  handleDateChangeEnd = async (date) => {
    await this.handleStatesDynamic("end_date", date.toISOString().slice(0, 10));
    console.log("end_date " + this.state.end_date);
  };

  handleStatesDynamic = async (start_date, date) => {
    this.setState({
      [start_date]: date,
    });
  };

  handleStates = async (start_date, end_date) => {
    this.setState({
      start_date: start_date,
      end_date: end_date,
    });
  };

  formSave = async () => {
    let { start_date, end_date } = this.state;
    await this.handleStates(start_date, end_date);
    $("#viewBtn").hide();
    $("#btnloader").show();
    await this.regenerate_report();
  };

  capitalizeFirstLetters = (str) => {
    return str.toLowerCase().replace(/^\w|\s\w/g, function (letter) {
      return letter.toUpperCase();
    });
  };

  formatAsCurrency = (amount) => {
    //console.log("amount: "+  typeof amount + " - " + amount);
    //console.log("amount: ", typeof amount);
    if (!amount) {
      amount = 0;
    }
    return amount.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: this.decimalPoint,
      maximumFractionDigits: this.decimalPoint,
    });
  };

  handleAccordionToggle(id, index) {
    const element = document.getElementById(id);
    const isActive = this.state.activeAccordion === index;

    // Close all accordions first
    const allAccordionItems = document.querySelectorAll(".accordion-row");
    allAccordionItems.forEach((item) => {
      item.classList.remove("show");
    });

    // Toggle the clicked accordion
    if (!isActive) {
      element.classList.add("show");
      this.setState({ activeAccordion: index });
    } else {
      this.setState({ activeAccordion: null });
    }
  }

  handleChangeSelect = async (event) => {
    const { target } = event;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const { name } = target;
    let field_value = "";
    let payment_method_title = "";
    if (value && value !== "") {
      let valArray = value.split("###");
      field_value = valArray[0];
      payment_method_title = valArray[1];
    }
    this.setState({
      [name]: field_value,
    });
    //Show Details
    if (field_value && field_value !== "") {
      const { formDetails, formGroups } = this.state;
      let group_record = this.findMatchingQuestions(
        field_value,
        formGroups,
        formDetails
      );
      this.setState({
        paymentDetails: group_record,
        payment_method_title: payment_method_title,
      });
      console.log("group_record: ", group_record);
    } else {
      this.setState({
        paymentDetails: [],
        payment_method_title: "",
      });
    }
  };

  findMatchingQuestions = (groupId, arrayA, arrayB) => {
    console.log("groupId: ", groupId);
    console.log("arrayA: ", arrayA);
    console.log("arrayB: ", arrayB);
    // Step 1: Find the group with the given ID in Array A
    const matchedGroup = arrayA.find((group) => group.id === groupId);
    if (!matchedGroup) {
      console.log("No group found with the given ID.");
      return [];
    }

    console.log("Matched Group:", matchedGroup);

    // Step 2: Collect all matched questions from Array B
    const matchedRecords = [];
    let arrayBs = JSON.parse(arrayB[0].response);

    arrayBs.forEach((item) => {
      console.log("Item Questions:", item.questions);
      //let response = JSON.parse(item.response);
      if (item.questions && Array.isArray(item.questions)) {
        const matches = item.questions.filter((q) =>
          matchedGroup.questions.includes(q.id)
        );
        console.log("Matches Found:", matches);
        matchedRecords.push(...matches); // Add matched questions to the result array
      }
    });

    console.log("Matched Records:", matchedRecords);
    return matchedRecords;
  };

  checkNumber = async (evt) => {
    var theEvent = evt || window.event;
    var key = theEvent.keyCode || theEvent.which;
    var keyCode = key;
    key = String.fromCharCode(key);
    if (key.length == 0) return;
    var regex = /^[0-9.\b]+$/;
    if (keyCode == 188 || keyCode == 190) {
      return;
    } else {
      if (!regex.test(key)) {
        theEvent.returnValue = false;
        if (theEvent.preventDefault) theEvent.preventDefault();
      }
    }
  };

  handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (!selectedFile) {
      return; // No file selected
    }

    // File type validation selectedFiles PDF, DOC, DOCX, ZIP, TXT, PNG, JPEG, JPG
    const allowedFileTypes = [".png", ".jpg", ".jpeg", ".pdf"];
    const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

    if (!allowedFileTypes.includes(`.${fileExtension}`)) {
      this.setState({
        uploadErrorMessage:
          "Invalid file type. Please select a valid file format.",
        uploadErrorTitle: "Invalid File",
        add_picture: "",
        add_picture_name: "",
      });
      return;
    }

    // File size validation (e.g., limit to 10MB)
    const maxSizeBytes = 10 * 1024 * 1024; // 10MB
    if (selectedFile.size > maxSizeBytes) {
      this.setState({
        uploadErrorMessage:
          "File size exceeds the limit (10MB). Please choose a smaller file.",
        uploadErrorTitle: "Invalid File",
        add_picture: "",
        add_picture_name: "",
      });
      return;
    }

    // Clear any previous errors
    this.setState({
      add_picture: selectedFile,
      uploadErrorMessage: "",
      uploadErrorTitle: "",
      add_picture_name: selectedFile.name,
    });
    //return true;
  };

  handleModalopen = () => {
    window.$("#modalInsertSalesOrder").modal("show");
  };

  handleFileUploadClick = () => {
    document.getElementById("fileInput").click();
  };

  parseExcelData = (data) => {
    try {
      const workbook = XLSX.read(data, { type: "binary" });
      return workbook;
    } catch (error) {
      console.error("Error parsing Excel data:", error);
      return null;
    }
  };

  parseSheetData = (sheet) => {
    const data = XLSX.utils.sheet_to_json(sheet, { header: 1 });
    const headers = data[0]; // Assuming first row contains headers
    const rows = data.slice(1); // Exclude headers
    return rows.map((row) =>
      headers.reduce((acc, header, index) => {
        acc[header] = row[index];
        return acc;
      }, {})
    );
  };

  handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.error("No file selected.");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;

      try {
        // Parse CSV data using XLSX
        const workbook = XLSX.read(data, { type: "binary" });
        const firstSheetName = workbook.SheetNames[0]; // Get the name of the first sheet (CSV will be treated as a single sheet)
        const sheet = workbook.Sheets[firstSheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet); // Convert sheet data to JSON

        console.log("Parsed CSV Data:", parsedData);

        this.setState((prevState) => ({
          tableData: [...prevState.tableData, ...parsedData],
          csvData: parsedData,
        }));
      } catch (error) {
        console.error("Error reading CSV file:", error);
      }
    };

    reader.readAsBinaryString(file);
  };

  processCsvData = async (csvData) => {
    console.log("Processing CSV Data:", csvData);

    if (
      !this.state.selected_agent_accountno ||
      !this.state.selected_client_accountno
    ) {
      this.setState({
        errorMessage:
          "Please select an agent and a client before importing orders.",
        isProcessing: false,
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 4000);
      return;
    }

    if (!Array.isArray(csvData) || csvData.length === 0) {
      this.setState({
        errorMessage:
          "CSV data is empty or invalid. Please upload a valid file.",
        isProcessing: false,
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 4000);
      return;
    }

    this.setState({
      isProcessing: true,
    });

    const excelDateToJSDate = (serial) => {
      const utcDays = Math.floor(serial - 25569);
      const utcValue = utcDays * 86400;
      const date = new Date(utcValue * 1000);
      return date.toISOString().split("T")[0];
    };

    const parsePercentage = (value) => {
      if (typeof value === "string" && value.includes("%")) {
        return parseFloat(value.replace("%", ""));
      }
      return parseFloat(value) * 100 || 0;
    };

    const calculateAgentCommission = (total, percentage) =>
      parseFloat((total * (percentage / 100)).toFixed(2));

    const standardizeKeys = (row) => {
      const keyMap = {
        "Invoice#": "Invoice#",
        "Invoice Date": "Invoice Date",
        "Collected Date": "Collected Date",
        "Net Invoice Amount ($)": "Net Invoice Amount ($)",
        "Collected Amount ($)": "Collected Amount ($)",
        "Agent Commission (%)": "Agent Commission (%)",
        "Agent Commission ($)": "Agent Commission ($)",
        "Paid (Y/N)": "Paid (Y/N)",
        "Quantity": "Quantity",
        "Item Name": "Item Name",
      };

      const standardizedRow = {};
      for (let key in row) {
        const standardizedKey = keyMap[key.trim()];
        if (standardizedKey) {
          standardizedRow[standardizedKey] = row[key];
        }
      }
      return standardizedRow;
    };

    csvData = csvData.map((row) => standardizeKeys(row));

    const validateOrder = (order) => {
      const requiredFields = [
        "Invoice#",
        "Invoice Date",
        "Collected Date",
        "Net Invoice Amount ($)",
        "Collected Amount ($)",
        "Agent Commission (%)",
        "Agent Commission ($)",
        "Paid (Y/N)",
        "Quantity",
        "Item Name",
      ];

      const missingFields = requiredFields.filter(
        (field) => !order[field] || order[field].toString().trim() === ""
      );

      if (missingFields.length > 0) {
        console.error(
          `Validation failed for order. Missing fields: ${missingFields.join(
            ", "
          )}. Row:`,
          order
        );
        return {
          isValid: false,
          message: `The following fields are missing or incomplete: ${missingFields.join(
            ", "
          )}.`,
        };
      }

      return { isValid: true };
    };

    for (let row of csvData) {
      const validationResult = validateOrder(row);
      if (!validationResult.isValid) {
        this.setState({
          errorMessage: validationResult.message,
          isProcessing: false,
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
        return; // Stop processing further if validation fails
      }
    }

    const orders = csvData.map((row) => {
      const percentage = parsePercentage(row["Agent Commission (%)"]);
      const commissionAmount = row["Agent Commission ($)"]
        ? parseFloat(row["Agent Commission ($)"]).toFixed(2)
        : calculateAgentCommission(row["Collected Amount ($)"], percentage);

      const netInvoiceAmount = parseFloat(row["Net Invoice Amount ($)"]);
      const collectedAmount = parseFloat(row["Collected Amount ($)"]);
      const rowQuantity = row["Quantity"];
      const invoiceNo = row["Invoice#"];
      const itemName = row["Item Name"];

      return {
        order_created_date: excelDateToJSDate(row["Invoice Date"]),
        dueDate: excelDateToJSDate(row["Collected Date"]),
        quote_sub_total: netInvoiceAmount,
        quote_total: netInvoiceAmount,
        collected_amount: collectedAmount,
        agentCommissionPercentage: percentage,
        agentCommissionAmount: parseFloat(commissionAmount),
        payment_status:
          collectedAmount < netInvoiceAmount
            ? "partial"
            : row["Paid (Y/N)"] === "Y"
            ? "paid"
            : "unpaid",
        service_name: itemName,
        quantity: rowQuantity,
        invoiceNo: invoiceNo
      };
    });

    console.log("Mapped Orders:", orders);

    try {
      const reportsData = await insertSalesorder(
        auth.getAccount(),
        auth.getToken(),
        this.state.selected_agent_accountno,
        this.state.selected_client_accountno,
        orders
      );

      if (
        reportsData.data.status === 403 ||
        reportsData.data.errors === "authentication missing" ||
        reportsData.data.errors === "jwt expired"
      ) {
        auth.logout();
      } else if (reportsData.data.status === 404) {
        this.setState({
          errorMessage: "There is some error while importing the Sales Order.",
          isProcessing: false,
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
      } else if (
        reportsData.data.status === 200 &&
        reportsData.data.message === "success"
      ) {
        this.setState({
          successMessage: "Sales Order imported successfully.",
          //isProcessing: false,
        });
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        this.setState({
          errorMessage: "Unexpected error occurred.",
          isProcessing: false,
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
      }
    } catch (error) {
      console.error("Error during import:", error);
      this.setState({
        errorMessage: "Failed to import Sales Order.",
        isProcessing: false,
      });
      setTimeout(() => {
        this.setState({ errorMessage: "" });
      }, 4000);
    }
  };

  downloadPdfRfq = async (d_f_name) => {
    var url = d_f_name;
    //let file_name = this.extractFileNameFromUrl(d_f_name);
    url = APP_LIVE_URL + "files_data/kyc_signup/" + d_f_name;
    //console.log("downloadPdfRfq: ", url);
    fetch(url)
      .then((x) => x.blob())
      .then((b) => {
        // console.log("B INSTANCE", b instanceof Blob);
        const url = window.URL.createObjectURL(b);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = url;
        a.download = d_f_name;
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        this.setState({
          errorMessage: "There is some error while downloading the attachment.",
        });
        setTimeout(() => {
          this.setState({ errorMessage: "" });
        }, 4000);
        // Handle any errors that occurred during the fetch
        console.error("Fetch error:", error);
      });
  };

  render() {
    //  let tableTitle = this.props.props.match.params.api_url.replaceAll("_", " ");
    const Theme = {
      palette: {
        primary: {
          contrastText: "#FFFFFF",
          dark: "#000000",
          main: "#000000",
          light: "#000000",
        },
      },
    };
    const { isOpen, value, start_date, end_date } = this.state;
    const formattedDateRange = start_date ? `${start_date} - ${end_date}` : "";
    return (
      <div className="nk-content ">
        <div className="container-fluid">
          {this.props.defaultSettings === "missing" ? <SettingsError /> : null}
          {/* MSA WARNING  START*/}
          {this.props.is_msa_signed === "No" ? <MsaWarning /> : null}

          {/* MSA WARNING END */}

          <div className="nk-content-inner">
            <div className="nk-content-body">
              {/* PAGE TITLE START */}
              <PageTitle name="Commission Reports" icon="icon ni ni-reports" />
              <div className="row align-items-end g-4">
                <div className="col-lg-3 col-xxl-3">
                  <div onClick={this.onToggle} className="form-group">
                    <TextField
                      label="Date Range"
                      variant="outlined"
                      fullWidth
                      value={formattedDateRange}
                      InputProps={{
                        onClick: this.onToggle,
                      }}
                      placeholder="Select Date Range"
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-xxl-3">
                  <div className="form-group">
                    <Select
                      options={this.state.all_active_agents}
                      name="selected_accountno"
                      placeholder="Select Agents"
                      value={this.state.selected_agent}
                      autoComplete="off"
                      emptyMessage="Clients not found"
                      onChange={this.handleChangeSearch}
                      isClearable
                      isSearchable
                      components={animatedComponents}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          lineHeight: "30px",
                        }),
                      }}
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-xxl-3">
                  <div className="form-group">
                    <Select
                      options={this.state.filtered_clients}
                      name="selected_accountno"
                      placeholder="Select Clients"
                      value={this.state.selected_client}
                      autoComplete="off"
                      emptyMessage="Clients not found"
                      onChange={this.handleChangeSearchClient}
                      isClearable
                      isSearchable
                      components={animatedComponents}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          lineHeight: "30px",
                        }),
                      }}
                    />
                  </div>
                </div>

                <div className="col-lg-3 col-xxl-3">
                  <div className="form-group">
                    <Select
                      options={this.state.filtered_orders}
                      name="selected_accountno"
                      placeholder="Select Orders"
                      value={this.state.selected_order}
                      autoComplete="off"
                      emptyMessage="Clients not found"
                      onChange={this.handleChangeSearchOrders}
                      isClearable
                      isSearchable
                      components={animatedComponents}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          lineHeight: "30px",
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="row g-4" style={{ display: "block" }}>
                <div className="text-right mt-2 mb-2">
                  <button
                    type="button"
                    id="importSalesOrderBtn"
                    className="btn btn-lg btn-secondary px-4 mr-2"
                    onClick={() => this.handleModalopen()}
                  >
                    <em className="icon ni ni-upload"></em>
                    <span>Import Orders</span>
                  </button>
                  <button
                    type="button"
                    id="viewBtn"
                    className="btn btn-lg btn-primary px-4"
                    onClick={() => {
                      this.regenerate_report();
                    }}
                    disabled={this.state.disabled}
                  >
                    <em class="icon ni ni-search"></em>
                    <span>Apply</span>
                  </button>
                  <button
                    id="btnloader"
                    class="btn btn-lg btn-primary px-4"
                    type="button"
                    style={{ display: "none" }}
                    disabled="disabled"
                  >
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span> Loading... </span>
                  </button>
                </div>
              </div>
              {isOpen && (
                <div
                  className="fixed inset-0 DateRange_margin"
                  style={{
                    zIndex: 1000,
                    position: "absolute",
                  }}
                >
                  <div
                    className="absolute inset-0"
                    onClick={this.onToggle}
                  ></div>
                  <div
                    className="absolute top-40 left-1/2 -translate-x-1/2 bg-white shadow-lg rounded-lg p-4"
                    style={{ zIndex: 1001 }}
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "10px",
                        right: "10px",
                        cursor: "pointer",
                        zIndex: 1002,
                      }}
                      onClick={this.onToggle}
                    >
                      <em className="icon ni ni-cross"></em>
                    </div>
                    <DateRangePicker
                      value={value}
                      onSelect={this.onSelect}
                      singleDateRange={true}
                      numberOfCalendars={2}
                      showLegend={true}
                    />
                  </div>
                </div>
              )}
              {/* PAGE TITLE END */}
              <div className="nk-block">
                <div className="row g-4">
                  {/* Table 5th Col Start */}
                  <div className="col-xxl-12">
                    <div className="nk-block nk-block-lg">
                      {/* START DATATABLE */}
                      {this.state.tableLoader === true ? (
                        tableLoader()
                      ) : (
                        <>
                          <DataTable
                            columns={this.state.columnsArray}
                            tableData={this.state.commissionReportsArray}
                            title=""
                          />
                        </>
                      )}

                      {/* END TABLE */}
                    </div>
                  </div>
                  {/* Table 5th Col End */}
                  {/* Link Modal MODAL START */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalDeposit"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em> Add
                              Commission Payment for the Order#{" "}
                              <sup className="text-primary">
                                ({this.state.orderno})
                              </sup>
                            </strong>
                          </div>
                          <a
                            className="close"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.modalHideMarketingPDF("modalDeposit");
                            }}
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          {this.state.depositErrorMessage !== "" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "20px" }}
                            >
                              <div className="alert alert-pro alert-danger">
                                <div className="alert-text">
                                  <h4>{this.state.emailErrorMessageTitle}</h4>

                                  <p>{this.state.depositErrorMessage}</p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.is_submitted === "No" ? (
                            <div
                              className="example-alert"
                              style={{ marginBottom: "20px" }}
                            >
                              <div className="alert alert-pro alert-warning">
                                <div className="alert-text">
                                  <h4>Billing Form Missing</h4>

                                  <p>
                                    Channel partner have not submissted the
                                    billing / payment details form.{" "}
                                  </p>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          {this.state.deposit_main_section === true ? (
                            <div class="row g-gs">
                              {this.state.fundSuccessMessage !== "" ? (
                                <div class="col-sm-12">
                                  <div class="alert alert-pro alert-success">
                                    <div class="alert-text">
                                      <h6>Success</h6>
                                      <p>{this.state.fundSuccessMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.fundErrorMessage !== "" ? (
                                <div class="col-sm-12">
                                  <div class="alert alert-pro alert-danger">
                                    <div class="alert-text">
                                      <h6>Error</h6>
                                      <p>{this.state.fundErrorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div class="col-sm-12">
                                <p>
                                  The process to pay a channel partner involves
                                  entering the payment amount, specifying the
                                  payment date, selecting a payment method
                                  (Submitted by the CP in payment form),
                                  uploading a receipt as proof of payment, and
                                  optionally adding a short note about the
                                  payment. Once all details are provided, the
                                  payment can be submitted by clicking the "Pay"
                                  button.
                                </p>
                              </div>
                              {this.state.formGroups &&
                              this.state.formGroups.length > 0 ? (
                                <div class="row g-4">
                                  <div className="col-md-6">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          <Tooltip
                                            title="The commission amount from the order to be deposited into the channel partner's account."
                                            placement="right"
                                          >
                                            <em
                                              className="icon ni ni-info"
                                              style={{
                                                color: "#6576ff",
                                              }}
                                            ></em>
                                          </Tooltip>{" "}
                                          Commission Amount <sup>*</sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group">
                                        <MutextField
                                          id="payment_amount"
                                          name="payment_amount"
                                          type="text"
                                          label=""
                                          value={this.state.payment_amount}
                                          onChange={this.handleChange}
                                          onKeyPress={() => {
                                            this.checkNumber();
                                          }}
                                          inputProps={{
                                            maxLength: 9, // Set the maximum length to 10 characters
                                          }}
                                          variant="outlined"
                                          fullWidth
                                        />
                                      </div>
                                      <div class="form-note">
                                        Remaining commission{" "}
                                        {this.formatAsCurrency(
                                          this.state.agent_pending_commision
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group">
                                      <label class="form-label">
                                        Payment Date
                                      </label>
                                      <div class="form-control-wrap">
                                        <MuiPickersUtilsProvider
                                          utils={DateFnsUtils}
                                        >
                                          <DateTimePicker
                                            variant="dialog"
                                            name="payment_date"
                                            id="payment_date"
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy HH:mm"
                                            fullWidth
                                            value={this.state.payment_date}
                                            onChange={this.handleDateChange}
                                            rientation="landscape"
                                          />
                                        </MuiPickersUtilsProvider>
                                      </div>
                                      <div class="form-note">
                                        Date of payment sent.
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="form-group">
                                      <div className="form-label-group">
                                        <label className="form-label">
                                          <Tooltip
                                            title="Receipt of the payment sent to the channel partner."
                                            placement="right"
                                          >
                                            <em
                                              className="icon ni ni-info"
                                              style={{
                                                color: "#6576ff",
                                              }}
                                            ></em>
                                          </Tooltip>{" "}
                                          Upload Receipt <sup></sup>
                                          <span className="text-danger"></span>
                                        </label>
                                      </div>
                                      <div className="form-control-group signatory_upload">
                                        <div class="custom-file">
                                          <input
                                            type="file"
                                            class="custom-file-input"
                                            id="add_picture"
                                            name="add_picture"
                                            defaultValue={
                                              this.state.add_picture
                                            }
                                            accept=".png, .jpg, .jpeg, .pdf"
                                            onChange={this.handleFileChange}
                                          />
                                          <label
                                            class="custom-file-label"
                                            for="add_picture"
                                          >
                                            Choose file
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div class="form-group">
                                      <label
                                        class="form-label"
                                        htmlFor="emailsubject"
                                      >
                                        Payment Method <sup>*</sup>
                                      </label>
                                      <div class="form-control-wrap ">
                                        <div class="form-control-select">
                                          <select
                                            name="selected_payment_method"
                                            class="form-control form-control-lg "
                                            id="default-06"
                                            onChange={this.handleChangeSelect}
                                          >
                                            <option value="" key="2weds">
                                              Select payment method
                                            </option>
                                            {this.state.formGroups.map(
                                              (grp, index) => (
                                                <option
                                                  value={`${grp.id}###${grp.group_title}`}
                                                  key={`aa${index}`}
                                                >
                                                  {grp.group_title}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                              {this.state.paymentDetails &&
                              this.state.paymentDetails.length > 0 ? (
                                <div class="col-sm-12">
                                  <div className="card card-bordered mt-1">
                                    <div class="card-inner-group">
                                      <div class="card-inner">
                                        <div class="sp-plan-head">
                                          <h6 class="title">
                                            Payment Method Details
                                          </h6>
                                        </div>
                                        <div
                                          class="sp-plan-desc sp-plan-desc-mb"
                                          id="paymentMethodDetails"
                                        >
                                          <table class="table table-bordered table-striped">
                                            <tbody class="text-dark">
                                              {this.state.paymentDetails.map(
                                                (pay, idx) => (
                                                  <tr key={`pid${idx}`}>
                                                    <td
                                                      style={{
                                                        fontWeight: "600",
                                                      }}
                                                    >
                                                      {pay.questionText}
                                                    </td>
                                                    <td>{pay.answer}</td>
                                                  </tr>
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              <div class="col-sm-12 mt-2">
                                <div class="form-group">
                                  <label
                                    class="form-label"
                                    htmlFor="emailcontent"
                                  >
                                    Notes <sup>*</sup>
                                  </label>
                                  <div class="form-control-wrap">
                                    <textarea
                                      class="form-control"
                                      id="payment_notes"
                                      name="payment_notes"
                                      value={this.state.payment_notes}
                                      onChange={this.handleChange}
                                      placeholder="Add payment notes with a maximum of 500 characters.."
                                      maxLength="500"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-12 mt-2 d-flex justify-content-end">
                                <div class="nk-block-head-content">
                                  <ul class="nk-block-tools gx-3">
                                    {this.state.add_funds_button === true ? (
                                      <button
                                        className="btn btn-primary"
                                        type="button"
                                        disabled
                                      >
                                        <span
                                          className="spinner-grow spinner-grow-sm"
                                          role="status"
                                          aria-hidden="true"
                                        ></span>
                                        <span> Processing... </span>
                                      </button>
                                    ) : (
                                      <li>
                                        <a
                                          onClick={() =>
                                            this.addFundsInAgentAccount()
                                          }
                                          style={{
                                            cursor: "pointer",
                                            color: "#fff",
                                          }}
                                          class="btn btn-primary"
                                        >
                                          <em class="icon ni ni-check-round-cut"></em>{" "}
                                          <span>Add Payments</span>{" "}
                                        </a>
                                      </li>
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                          ) : null}
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* LINK MODAL End */}
                  {/* Link Modal MODAL START */}
                  <div
                    className="modal fade zoom"
                    tabIndex="-1"
                    id="modalRequests"
                  >
                    <div
                      className="modal-dialog modal-xl modal-dialog-top"
                      role="document"
                    >
                      <div className="modal-content">
                        <div className="modal-header">
                          <div
                            className="modal-title"
                            style={{ textAlign: "center", fontSize: "20px" }}
                          >
                            <strong>
                              <em className="icon ni ni-list-round"></em> CP
                              Commission Requests
                            </strong>
                          </div>
                          <a
                            className="close"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.modalHideMarketingPDF("modalRequests");
                            }}
                          >
                            <em className="icon ni ni-cross"></em>
                          </a>
                        </div>

                        <div className="modal-body">
                          {this.state.request_main_section === true ? (
                            FormLoader()
                          ) : (
                            <div class="row g-gs">
                              {this.state.requestErrorMessage !== "" ? (
                                <div class="col-sm-12">
                                  <div className="example-alert">
                                    <div className="alert alert-pro alert-danger">
                                      <div className="alert-text">
                                        <h4>
                                          {this.state.requestErrorMessageTitle}
                                        </h4>

                                        <p>{this.state.requestErrorMessage}</p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}

                              {this.state.requestDetails &&
                              this.state.requestDetails.length > 0 ? (
                                <div class="col-sm-12">
                                  <div className="card card-bordered mt-1">
                                    <div class="card-inner-group">
                                      <div class="card-inner">
                                        <div class="sp-plan-head">
                                          <h6 class="title">Request Details</h6>
                                        </div>
                                        <div
                                          class="sp-plan-desc sp-plan-desc-mb"
                                          id="paymentMethodDetails"
                                        >
                                          <div id="no-more-tables-new">
                                            <table class="table table-bordered">
                                              <thead class="thead-light">
                                                <tr>
                                                  <th class="tb-col-os">
                                                    <span class="overline-title">
                                                      Date
                                                    </span>
                                                  </th>
                                                  <th class="tb-col-os">
                                                    <span class="overline-title">
                                                      Payment Method
                                                    </span>
                                                  </th>
                                                  <th class="tb-col-os">
                                                    <span class="overline-title">
                                                      Payment Details
                                                    </span>
                                                  </th>
                                                  <th class="tb-col-ip">
                                                    <span class="overline-title">
                                                      Notes
                                                    </span>
                                                  </th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state.requestDetails.map(
                                                  (pay, idx) => (
                                                    <>
                                                      <tr key={`pid${idx}`}>
                                                        <td class="tb-col-os">
                                                          {this.format_date(
                                                            pay.dateAdded
                                                          )}
                                                        </td>
                                                        <td class="tb-col-os">
                                                          {
                                                            pay.payment_method_title
                                                          }
                                                        </td>
                                                        <td class="tb-col-os text-center">
                                                          <a
                                                            onClick={() =>
                                                              this.handleAccordionToggle(
                                                                `accordion-item-${idx}`,
                                                                idx
                                                              )
                                                            }
                                                            style={{
                                                              cursor: "pointer",
                                                            }}
                                                            className="text-primary fs-20px"
                                                          >
                                                            <em
                                                              className={`icon ni ${
                                                                this.state
                                                                  .activeAccordion ===
                                                                idx
                                                                  ? "ni-minus"
                                                                  : "ni-plus"
                                                              }`}
                                                            ></em>
                                                          </a>
                                                        </td>
                                                        <td class="tb-col-ip">
                                                          {pay.email_content}
                                                        </td>
                                                      </tr>
                                                      <tr
                                                        id={`accordion-item-${idx}`}
                                                        className="accordion-row collapse"
                                                      >
                                                        <td colSpan="4">
                                                          <div
                                                            className="accordion-inner"
                                                            style={{
                                                              borderRadius:
                                                                "8px",
                                                              padding: "15px",
                                                            }}
                                                          >
                                                            <h4
                                                              style={{
                                                                fontSize:
                                                                  "18px",
                                                                marginBottom:
                                                                  "15px",
                                                                color: "#333",
                                                                fontWeight:
                                                                  "bold",
                                                              }}
                                                            >
                                                              {
                                                                pay.payment_method_title
                                                              }{" "}
                                                              Details
                                                            </h4>
                                                            <div
                                                              style={{
                                                                lineHeight:
                                                                  "1.6",
                                                                color: "#555",
                                                              }}
                                                            >
                                                              <div
                                                                dangerouslySetInnerHTML={{
                                                                  __html:
                                                                    pay.payment_details,
                                                                }}
                                                              />
                                                            </div>
                                                          </div>
                                                        </td>
                                                      </tr>
                                                    </>
                                                  )
                                                )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          )}
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* LINK MODAL End */}
                  {/* New Client Modal Start */}
                  <div
                    className="modal fade"
                    tabindex="-1"
                    id="modalInsertSalesOrder"
                  >
                    <div
                      className="modal-dialog modal-dialog-top modal-xl"
                      role="document"
                    >
                      <div className="modal-content">
                        <a
                          href="#"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <em className="icon ni ni-cross"></em>
                        </a>
                        <div className="modal-header">
                          <h5 className="modal-title">
                            <em
                              className="icon ni ni-upload"
                              style={{ marginRight: "10px" }}
                            ></em>
                            <span> Import Orders</span>
                            <a
                              onClick={() =>
                                this.downloadPdfRfq("order_import.csv")
                              }
                              style={{ cursor: "pointer", marginLeft: "15px" }}
                            >
                              <span class="badge badge-outline-primary">
                                <em class="icon ni ni-download"></em> Download
                                Sample
                              </span>
                            </a>
                          </h5>
                        </div>
                        <div className="modal-body">
                          <div className="row g-4">
                            {this.state.errorMessage !== "" ? (
                              <div className="col-md-12 mb-3">
                                <div
                                  className="example-alert example-alert-revoke"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-danger">
                                    <div className="alert-text">
                                      <h4>Error</h4>
                                      <p>{this.state.errorMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            {this.state.successMessage !== "" ? (
                              <div className="col-md-12 mb-3">
                                <div
                                  className="example-alert example-alert-revoke"
                                  style={{ marginBottom: "15px" }}
                                >
                                  <div className="alert alert-pro alert-success">
                                    <div className="alert-text">
                                      <h4>Success</h4>
                                      <p>{this.state.successMessage}</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null}
                            <div className="col-md-6">
                              <div className="form-group">
                                <div class="form-label-group">
                                  <label class="form-label">
                                    Select Agents
                                    <span class="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <Select
                                    options={this.state.all_active_agents}
                                    name="selected_accountno"
                                    placeholder="Select Agents"
                                    value={this.state.selected_agent}
                                    autoComplete="off"
                                    emptyMessage="Clients not found"
                                    onChange={this.handleChangeSearch}
                                    isClearable
                                    isSearchable
                                    components={animatedComponents}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        lineHeight: "30px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div class="form-label-group">
                                  <label class="form-label">
                                    Select Clients
                                    <span class="text-danger"></span>
                                  </label>
                                </div>
                                <div className="form-control-group">
                                  <Select
                                    options={this.state.filtered_clients}
                                    name="selected_accountno"
                                    placeholder="Select Clients"
                                    value={this.state.selected_client}
                                    autoComplete="off"
                                    emptyMessage="Clients not found"
                                    onChange={this.handleChangeSearchClient}
                                    isClearable
                                    isSearchable
                                    components={animatedComponents}
                                    styles={{
                                      control: (baseStyles, state) => ({
                                        ...baseStyles,
                                        lineHeight: "30px",
                                      }),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="col-md-6">
                              <div className="form-group">
                                <div className="form-control-group"></div>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="form-label-group">
                                  <label className="form-label">
                                    Upload File
                                    <sup> *</sup>
                                  </label>
                                </div>
                                <div
                                  className="form-control-group"
                                  id="fileContainer"
                                >
                                  <input
                                    type="file"
                                    id="document_file"
                                    className="file-block"
                                    name="document_file"
                                    defaultValue={this.state.document_file}
                                    accept=".csv"
                                    onChange={(e) => this.handleFileUpload(e)}
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 d-flex justify-content-end">
                              <button
                                type="button"
                                onClick={() =>
                                  this.processCsvData(this.state.csvData)
                                }
                                className="btn btn-primary"
                                disabled={
                                  this.state.disabled || this.state.isProcessing
                                } // disable button when processing
                              >
                                {this.state.isProcessing ? (
                                  <span>Processing...</span>
                                ) : (
                                  <span>Import</span>
                                )}
                                <em className="icon ni ni-upload"></em>
                              </button>
                            </div>
                            <div className="col-md-12">
                              <div className="example-alert">
                                <div className="alert alert-light">
                                  <ul className="list">
                                    <li>Supported file formats include: CSV</li>
                                    <li>Invoice# should be a six digit number.</li>
                                    <li>Do not change the first row from sample CSV file.</li>
                                    <li>All of the columns in CSV file are required.</li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* New Client Modal End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    defaultSettings: state.settings,
    is_msa_signed: state.is_msa_signed,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSettings: (payload) => dispatch(addSettings(payload)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Reports);
