import axios from "axios";
import $ from "jquery";
import CryptoJS from "crypto-js";
import Cookies from "js-cookie";
import { axiosConfig } from "./utility";

export const accountVerification = async (token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "emailUserVerification",
          JSON.stringify({
            emailToken: token,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          }),
          axiosConfig
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const resendCodeFun = async (emailUser) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resendEmail",
          JSON.stringify({
            email: emailUser,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          }),
          axiosConfig
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const forgotEmailSend = async (emailUser) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "forgotPassword",
          JSON.stringify({
            email: emailUser,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          }),
          axiosConfig
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const setNewPassword = async (email, password, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "setNewPassword",
          JSON.stringify({
            email: email,
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            password: password,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const getAccountData = async (
  accountno,
  token,
  sub_accountno,
  usertype
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "dashboard",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            sub_accountno: sub_accountno,
            usertype: usertype,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listServices = async (accountno, token, service_type_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/list_services",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            service_type_id: service_type_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getService = async (accountno, token, service_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/get_service",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            service_id: service_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getBundleService = async (accountno, token, service_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/get_bundle_service",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            service_id: service_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getCSVData = async (accountno, token, file_name) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/get_service_ratedeck",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            file_name: file_name,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listServicesGroups = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/list_services_groups",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listServicesAgents = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/list_services_agents",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listAgentSignedAgreements = async (accountno, token, agent_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/list_agent_signed_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listSubAgent = async (accountno, token, agent_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/list_sub_agent",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listAssignedServices = async (accountno, token, company_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/list_services_assigned",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            company_id: company_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listAssignedServicesWithProjection = async (
  accountno,
  token,
  company_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "services/list_services_assigned_with_projection",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            company_id: company_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listClientAllowedServices = async (
  accountno,
  token,
  client_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "services/list_client_allowed_services",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const updateClientAllowedServices = async (
  accountno,
  token,
  client_accountno,
  assigned_services
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "services/update_client_allowed_services",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            assigned_services: assigned_services,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listDefaultServices = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/list_default_services",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listAllServices = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/list_all_services",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listAddedServices = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/list_added_services",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const assignServicesToCompany = async (
  accountno,
  token,
  did_service,
  tfn_service,
  term_service,
  company_id,
  nrc_did,
  mrc_did,
  rate_did,
  nrc_tfn,
  mrc_tfn,
  rate_tfn,
  rate_term,
  sms_service,
  rate_smsin,
  rate_smsout,
  ivr_service,
  liveagent_service,
  rate_ivr,
  rate_liveagent
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/update_service_company",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            did_service: did_service,
            tfn_service: tfn_service,
            term_service: term_service,
            company_id: company_id,
            nrc_did: nrc_did,
            mrc_did: mrc_did,
            rate_did: rate_did,
            nrc_tfn: nrc_tfn,
            mrc_tfn: mrc_tfn,
            rate_tfn: rate_tfn,
            rate_term: rate_term,
            sms_service: sms_service,
            rate_smsin: rate_smsin,
            rate_smsout: rate_smsout,
            ivr_service: ivr_service,
            liveagent_service: liveagent_service,
            rate_ivr: rate_ivr,
            rate_liveagent: rate_liveagent,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const listClients = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listSubClients = async (accountno, token, client_accountno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/list_sub_clients",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listSubClientRights = async (
  accountno,
  token,
  client_accountno,
  parent_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/list_sub_client_rights",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            parent_accountno: parent_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addSubCLientRights = async (
  accountno,
  token,
  client_accountno,
  parent_accountno,
  main_selected_menus
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/add_sub_client_rights",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            parent_accountno: parent_accountno,
            selected_menus: main_selected_menus,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const profileUpdate = async (
  accountno,
  token,
  name,
  title,
  comp_name,
  phone,
  address,
  city,
  states,
  zip,
  password,
  usertype,
  sub_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "dashboard/profile_update",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            name: name,
            title: title,
            comp_name: comp_name,
            phone: phone,
            address: address,
            city: city,
            states: states,
            zip: zip,
            password: password,
            usertype: usertype,
            sub_accountno: sub_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const saveAgreement = async (
  accountno,
  token,
  signature,
  content,
  vendor_signature
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agreements/msaSigning",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            signature: signature,
            agreement_content: content,
            vendor_signature: vendor_signature,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const saveMsaAgreement = async (
  accountno,
  token,
  signature,
  content,
  vendor_signature
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agreements/msaSigning",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            signature: signature,
            agreement_content: content,
            vendor_signature: vendor_signature,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const getMsaInfo = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "dashboard/showMSA",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getDashboardCounts = async (
  accountno,
  token,
  start_date,
  end_date
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/count",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            start_date: start_date,
            end_date: end_date,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getReportsData = async (
  accountno,
  token,
  link,
  start_date,
  end_date,
  type
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "reports/" + link,
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            start_date: start_date,
            end_date: end_date,
            type: type,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getCommissionReports = async (
  accountno,
  token,
  start_date,
  end_date,
  orderno,
  client_accountno,
  agent_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "reports/get_commission_reports",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            start_date: start_date,
            end_date: end_date,
            orderno: orderno,
            client_accountno: client_accountno,
            agent_accountno: agent_accountno
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const serviceRatesUpdate = async (
  accountno,
  token,
  nrc,
  mrc,
  rate,
  serviceId,
  service_title,
  service_type,
  product_desc,
  sms_in,
  sms_out,
  agent_mrc_comission,
  agent_nrc_comission,
  agent_rate_comission,
  default_mrc,
  default_rate,
  default_nrc,
  sms_in_comission,
  sms_out_comission,
  cancellation_fee,
  per_min_type,
  misc_rows,
  generic_rows,
  defaultPortIn,
  portin,
  agent_portin_comission,
  defaultPortOut,
  portout,
  agent_portout_comission,
  default_sms_in,
  default_sms_out,
  cnam_mrc,
  cnam_dip,
  cnam_dip_local,
  default_cnam_mrc,
  default_cnam_dip,
  default_cnam_dip_local,
  cnam_mrc_comission,
  cnam_dip_local_comission,
  cnam_dip_comission,
  defaultSessionRate,
  marketing_content,
  marketing_link,
  sku,
  kyc_required,
  capture_projection,
  allow_rfq,
  nda_required,
  select_integration,
  documentTitle,
  product_summary,
  rate_margin,
  total_commission,
  select_service,
  product_title,
  is_default,
  selected_agreement
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/update_service_rates",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            nrc: nrc,
            mrc: mrc,
            rate: rate,
            serviceId: serviceId,
            service_title: service_title,
            service_type: service_type,
            sms_in: sms_in,
            sms_out: sms_out,
            agent_mrc_comission: agent_mrc_comission,
            agent_nrc_comission: agent_nrc_comission,
            agent_rate_comission: agent_rate_comission,
            default_mrc: default_mrc,
            default_rate: default_rate,
            default_nrc: default_nrc,
            sms_in_comission: sms_in_comission,
            sms_out_comission: sms_out_comission,
            product_desc: product_desc,
            cancellation_fee: cancellation_fee,
            per_min_type: per_min_type,
            misc_rows: misc_rows,
            generic_rows: generic_rows,
            defaultPortIn: defaultPortIn,
            portin: portin,
            agent_portin_comission: agent_portin_comission,
            defaultPortOut: defaultPortOut,
            portout: portout,
            agent_portout_comission: agent_portout_comission,
            default_sms_in: default_sms_in,
            default_sms_out: default_sms_out,
            cnam_mrc: cnam_mrc,
            cnam_dip: cnam_dip,
            cnam_dip_local: cnam_dip_local,
            default_cnam_mrc: default_cnam_mrc,
            default_cnam_dip: default_cnam_dip,
            default_cnam_dip_local: default_cnam_dip_local,
            cnam_mrc_comission: cnam_mrc_comission,
            cnam_dip_local_comission: cnam_dip_local_comission,
            cnam_dip_comission: cnam_dip_comission,
            defaultSessionRate: defaultSessionRate,
            marketing_content: marketing_content,
            marketing_link: marketing_link,
            kyc_required: kyc_required,
            capture_projection: capture_projection,
            allow_rfq: allow_rfq,
            nda_required: nda_required,
            sku: sku,
            integration: select_integration,
            document_title: documentTitle,
            product_summary: product_summary,
            rate_margin: rate_margin,
            total_commission: total_commission,
            select_service: select_service,
            product_title: product_title,
            is_default: is_default,
            selected_agreement: selected_agreement,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const serviceDefaultUpdate = async (
  accountno,
  token,
  id,
  service_id,
  service_type
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/update_service_default",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            id: id,
            service_id: service_id,
            service_type: service_type,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const publishService = async (
  accountno,
  token,
  id,
  service_id,
  service_type
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/publish_in_switch_portal",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            id: id,
            service_id: service_id,
            service_type: service_type,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const unpublishService = async (
  accountno,
  token,
  id,
  service_id,
  service_type
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/unpublish_in_switch_portal",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            id: id,
            service_id: service_id,
            service_type: service_type,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const addNewService = async (
  accountno,
  token,
  nrc,
  mrc,
  rate,
  serviceId,
  select_service,
  service_type,
  title,
  service_title,
  service_desc,
  nrc_type,
  mrc_type,
  min_sms_in_type,
  min_sms_out_type,
  sms_in,
  sms_out,
  agent_mrc_comission,
  agent_nrc_comission,
  agent_rate_comission,
  defaultMrc,
  defaultRate,
  defaultNrc,
  sms_in_comission,
  sms_out_comission,
  cancellation_fee,
  per_min_type,
  misc_rows,
  generic_rows,
  defaultPortIn,
  portin,
  agent_portin_comission,
  defaultPortOut,
  portout,
  agent_portout_comission,
  defaultSmsIn,
  defaultSmsOut,
  cnam_mrc,
  cnam_dip,
  cnam_dip_local,
  default_cnam_mrc,
  default_cnam_dip,
  default_cnam_dip_local,
  cnam_mrc_comission,
  cnam_dip_local_comission,
  cnam_dip_comission,
  marketing_content,
  marketing_link,
  sku,
  kyc_required,
  capture_projection,
  allow_rfq,
  nda_required,
  select_integration,
  product_summary,
  rate_margin,
  total_commission,
  is_default,
  selected_agreement
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/add_new_service",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            nrc: nrc,
            mrc: mrc,
            rate: rate,
            serviceId: serviceId,
            select_service: select_service,
            service_type: service_type,
            title: title,
            service_title: service_title,
            service_desc: service_desc,
            nrc_type: nrc_type,
            mrc_type: mrc_type,
            min_sms_in_type: min_sms_in_type,
            min_sms_out_type: min_sms_out_type,
            sms_in: sms_in,
            sms_out: sms_out,
            agent_mrc_comission: agent_mrc_comission,
            agent_nrc_comission: agent_nrc_comission,
            agent_rate_comission: agent_rate_comission,
            defaultMrc: defaultMrc,
            defaultRate: defaultRate,
            defaultNrc: defaultNrc,
            sms_in_comission: sms_in_comission,
            sms_out_comission: sms_out_comission,
            cancellation_fee: cancellation_fee,
            per_min_type: per_min_type,
            misc_rows: misc_rows,
            generic_rows: generic_rows,
            defaultPortIn: defaultPortIn,
            portin: portin,
            agent_portin_comission: agent_portin_comission,
            defaultPortOut: defaultPortOut,
            portout: portout,
            agent_portout_comission: agent_portout_comission,
            defaultSmsIn: defaultSmsIn,
            defaultSmsOut: defaultSmsOut,
            cnam_mrc: cnam_mrc,
            cnam_dip: cnam_dip,
            cnam_dip_local: cnam_dip_local,
            default_cnam_mrc: default_cnam_mrc,
            default_cnam_dip: default_cnam_dip,
            default_cnam_dip_local: default_cnam_dip_local,
            cnam_mrc_comission: cnam_mrc_comission,
            cnam_dip_local_comission: cnam_dip_local_comission,
            cnam_dip_comission: cnam_dip_comission,
            marketing_content: marketing_content,
            marketing_link: marketing_link,
            kyc_required: kyc_required,
            capture_projection: capture_projection,
            allow_rfq: allow_rfq,
            sku: sku,
            nda_required: nda_required,
            integration: select_integration,
            product_summary: product_summary,
            rate_margin: rate_margin,
            total_commission: total_commission,
            is_default: is_default,
            selected_agreement: selected_agreement,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const addNewBundle = async (
  accountno,
  token,
  bundle_title,
  per_month_rate,
  per_year_rate,
  select_service_did,
  select_service_tfn,
  select_service_teams,
  select_service_term,
  select_service_tfn_term,
  select_service_az_term,
  select_service_live_agent,
  select_service_sms,
  product_desc,
  type_limited_did,
  type_limited_tfn,
  type_limited_term,
  type_limited_teams,
  type_limited_ivr,
  type_limited_live_agent,
  type_limited_tfn_term,
  type_limited_az_term,
  type_limited_sms,
  did_mou,
  tfn_mou,
  term_mou,
  teams_mou,
  ivr_mou,
  live_agent_mou,
  tfn_term_mou,
  az_term_mou,
  sms_mou,
  did_sessions,
  tfn_sessions,
  term_sessions,
  teams_sessions,
  ivr_sessions,
  live_agent_sessions,
  tfn_term_sessions,
  az_term_sessions,
  sms_sessions,
  main_selected_services,
  select_service_cnam,
  type_limited_cnam,
  cnam_mou,
  cnam_sessions,
  marketing_content,
  marketing_link,
  package_status
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/add_bundle_service",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            bundle_title: bundle_title,
            per_month_rate: per_month_rate,
            per_year_rate: per_year_rate,
            select_service_did: select_service_did,
            select_service_tfn: select_service_tfn,
            select_service_teams: select_service_teams,
            select_service_term: select_service_term,
            select_service_tfn_term: select_service_tfn_term,
            select_service_az_term: select_service_az_term,
            select_service_live_agent: select_service_live_agent,
            select_service_sms: select_service_sms,
            product_desc: product_desc,
            type_limited_did: type_limited_did,
            type_limited_tfn: type_limited_tfn,
            type_limited_term: type_limited_term,
            type_limited_teams: type_limited_teams,
            type_limited_ivr: type_limited_ivr,
            type_limited_live_agent: type_limited_live_agent,
            type_limited_tfn_term: type_limited_tfn_term,
            type_limited_az_term: type_limited_az_term,
            type_limited_sms: type_limited_sms,
            did_mou: did_mou,
            tfn_mou: tfn_mou,
            term_mou: term_mou,
            teams_mou: teams_mou,
            ivr_mou: ivr_mou,
            live_agent_mou: live_agent_mou,
            tfn_term_mou: tfn_term_mou,
            az_term_mou: az_term_mou,
            sms_mou: sms_mou,
            did_sessions: did_sessions,
            tfn_sessions: tfn_sessions,
            term_sessions: term_sessions,
            teams_sessions: teams_sessions,
            ivr_sessions: ivr_sessions,
            live_agent_sessions: live_agent_sessions,
            tfn_term_sessions: tfn_term_sessions,
            az_term_sessions: az_term_sessions,
            sms_sessions: sms_sessions,
            main_selected_services: main_selected_services,
            select_service_cnam: select_service_cnam,
            type_limited_cnam: type_limited_cnam,
            cnam_mou: cnam_mou,
            cnam_sessions: cnam_sessions,
            marketing_content: marketing_content,
            marketing_link: marketing_link,
            package_status: package_status,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const signedAgreements = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agreements/list_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listPages = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "cms/list_pages",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addNewPage = async (
  accountno,
  token,
  page_title,
  seqno,
  status,
  is_published,
  page_content,
  page_link
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "cms/add_page",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            page_title: page_title,
            seqno: seqno,
            status: status,
            is_published: is_published,
            page_content: page_content,
            page_link: page_link,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getPageData = async (accountno, token, page_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "cms/get_page_data",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            page_id: page_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const updatePage = async (
  accountno,
  token,
  page_title,
  seqno,
  status,
  is_published,
  page_content,
  page_link,
  page_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "cms/update_page",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            page_title: page_title,
            seqno: seqno,
            status: status,
            is_published: is_published,
            page_content: page_content,
            page_link: page_link,
            page_id: page_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listDomains = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "cms/list_domains",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deletePage = async (accountno, token, delete_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "cms/delete_page",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            delete_id: delete_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listMenus = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "cms/list_menus",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addNewMenu = async (
  accountno,
  token,
  menu_title,
  status,
  seqno,
  items
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "cms/add_menu",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            menu_title: menu_title,
            seqno: seqno,
            status: status,
            items: items,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deleteMenu = async (accountno, token, delete_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "cms/delete_menu",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            delete_id: delete_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getMenuData = async (accountno, token, menu_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "cms/get_menu_data",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            menu_id: menu_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const updateMenu = async (
  accountno,
  token,
  menu_title,
  seqno,
  status,
  items,
  menu_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "cms/update_menu",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            menu_title: menu_title,
            seqno: seqno,
            status: status,
            items: items,
            menu_id: menu_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveThemePage = async (accountno, token, pageLink, jsondata) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "cms/save_theme_page",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            pageLink: pageLink,
            jsondata: jsondata,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveThemeHtml = async (accountno, token, pageLink, jsondata) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "cms/save_theme_html",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            pageLink: pageLink,
            jsondata: jsondata,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getFileData = async (accountno, token, pageLink) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "cms/get_theme_page_path",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            pageLink: pageLink,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAgreementData = async (accountno, token, pdf_path) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agreements/get_agreement_data",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            pdf_path: pdf_path,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addCsvFile = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "uploads/list_pages",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8;",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const verfiyDomain = async (accountno, token, domain_name) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "cms/verify_domain",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            domain_name: domain_name,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listDashboard = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "dashboard/list_dashboard",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listTerminationSessions = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "dashboard/list_termination_session",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSixMonthGrowth = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "dashboard/get_six_month_growth",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listAgents = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/list_agents",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

/***######### Agents Section #########***/

export const resendAgentActivation = async (accountno, token, agent_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/resend_agent_activation",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addNewAgent = async (
  accountno,
  token,
  title,
  name,
  agent_email,
  agent_password,
  agent_phone,
  agent_company,
  agent_address,
  agent_address2,
  agent_city,
  agent_state,
  agent_zip,
  agent_country,
  notify,
  account_status,
  passwordRequired
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/add_new_agent",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            title: title,
            name: name,
            agent_email: agent_email,
            agent_password: agent_password,
            agent_phone: agent_phone,
            agent_company: agent_company,
            agent_address: agent_address,
            agent_address2: agent_address2,
            agent_city: agent_city,
            agent_state: agent_state,
            agent_zip: agent_zip,
            agent_country: agent_country,
            notify: notify,
            account_status: account_status,
            passwordRequired: passwordRequired,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSingleAgent = async (accountno, token, agent_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/get_single_agent",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAgentLogin = async (accountno, token, agent_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/get_agent_login",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const editAgent = async (
  accountno,
  token,
  title,
  name,
  agent_email,
  agent_password,
  agent_phone,
  agent_id,
  agent_company,
  agent_status,
  sharelink_limit,
  agent_address,
  agent_address2,
  agent_city,
  agent_state,
  agent_zip,
  agent_country,
  notifyCheckBox,
  statusChanged,
  agent_isVerified
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/edit_agent",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            title: title,
            name: name,
            agent_email: agent_email,
            agent_password: agent_password,
            agent_phone: agent_phone,
            agent_id: agent_id,
            agent_company: agent_company,
            agent_status: agent_status,
            sharelink_limit: sharelink_limit,
            agent_address: agent_address,
            agent_address2: agent_address2,
            agent_city: agent_city,
            agent_state: agent_state,
            agent_zip: agent_zip,
            agent_country: agent_country,
            notifyCheckBox: notifyCheckBox,
            statusChanged: statusChanged,
            agent_isVerified: agent_isVerified,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const regenerateLink = async (accountno, token, agent_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/regenerate_link",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const cancelLink = async (accountno, token, agent_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/cancel_link",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const sendEmail = async (
  accountno,
  token,
  email_to,
  signup_link,
  agent_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/send_email",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            email_to: email_to,
            signup_link: signup_link,
            agent_id: agent_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAgentSignup = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/get_super_agent_signup",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const regenerateAgentLink = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/regenerate_super_agent_link",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const cancelAgentLink = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/cancel_super_agent_link",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const assignServicesToAgent = async (
  accountno,
  token,
  did_service,
  tfn_service,
  term_service,
  agent_id,
  nrc_did,
  mrc_did,
  rate_did,
  nrc_tfn,
  mrc_tfn,
  rate_tfn,
  rate_term,
  sms_service,
  rate_smsin,
  rate_smsout,
  ivr_service,
  liveagent_service,
  rate_ivr,
  rate_liveagent
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/update_service_agent",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            did_service: did_service,
            tfn_service: tfn_service,
            term_service: term_service,
            agent_id: agent_id,
            nrc_did: nrc_did,
            mrc_did: mrc_did,
            rate_did: rate_did,
            nrc_tfn: nrc_tfn,
            mrc_tfn: mrc_tfn,
            rate_tfn: rate_tfn,
            rate_term: rate_term,
            sms_service: sms_service,
            rate_smsin: rate_smsin,
            rate_smsout: rate_smsout,
            ivr_service: ivr_service,
            liveagent_service: liveagent_service,
            rate_ivr: rate_ivr,
            rate_liveagent: rate_liveagent,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });

  return res;
};

export const listAgentAgreements = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/list_agent_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listAgentAgreementsWithDefaults = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "agents/list_agent_agreements_with_defaults",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deleteAgreement = async (accountno, token, agreement_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/delete_agent_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAgentAgreement = async (accountno, token, agreement_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/get_agent_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAgentAgreementSequence = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/get_agent_agreement_sequence",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addAgreement = async (
  accountno,
  token,
  agreement_title,
  agreement_instruction,
  isactive,
  ismandatory,
  agreement_content,
  agreement_type,
  selectedAgents,
  signatory_id,
  add_agreement_type,
  selected_agreement,
  max_seqno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/add_agent_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_title: agreement_title,
            agreement_instruction: agreement_instruction,
            isactive: isactive,
            ismandatory: ismandatory,
            agreement_content: agreement_content,
            agreement_type: agreement_type,
            selectedAgents: selectedAgents,
            signatory_id: signatory_id,
            add_agreement_type: add_agreement_type,
            selected_agreement: selected_agreement,
            seqno: max_seqno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const editAgreement = async (
  accountno,
  token,
  agreement_title,
  agreement_instruction,
  isactive,
  ismandatory,
  agreement_content,
  agreement_id,
  agreement_type,
  selectedAgents,
  signatory_id,
  edit_type,
  max_seqno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/edit_agent_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_title: agreement_title,
            agreement_instruction: agreement_instruction,
            isactive: isactive,
            ismandatory: ismandatory,
            agreement_content: agreement_content,
            agreement_type: agreement_type,
            agreement_id: agreement_id,
            selectedAgents: selectedAgents,
            signatory_id: signatory_id,
            edit_type: edit_type,
            seqno: max_seqno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listClientAgreements = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/list_client_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listClientServiceAgreements = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/list_client_service_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addClientAgreement = async (
  accountno,
  token,
  agreement_title,
  agreement_instruction,
  isactive,
  ismandatory,
  agreement_content,
  agreement_type,
  service_type,
  signatory_id,
  selectedClients,
  add_agreement_type,
  selected_agreement,
  max_seqno,
  isLocked,
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/add_client_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_title: agreement_title,
            agreement_instruction: agreement_instruction,
            isactive: isactive,
            ismandatory: ismandatory,
            agreement_content: agreement_content,
            agreement_type: agreement_type,
            service_type: service_type,
            signatory_id: signatory_id,
            selectedClients: selectedClients,
            add_agreement_type: add_agreement_type,
            selected_agreement: selected_agreement,
            max_seqno: max_seqno,
            isLocked: isLocked,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listClientAgreementsArchived = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "clients/list_client_agreements_archived",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addClientServiceAgreement = async (
  accountno,
  token,
  agreement_title,
  agreement_instruction,
  isactive,
  ismandatory,
  agreement_content,
  agreement_type,
  service_type,
  signatory_id,
  max_seqno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "clients/add_client_service_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_title: agreement_title,
            agreement_instruction: agreement_instruction,
            isactive: isactive,
            ismandatory: ismandatory,
            agreement_content: agreement_content,
            agreement_type: agreement_type,
            service_type: service_type,
            signatory_id: signatory_id,
            seqno: max_seqno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getClientAgreement = async (accountno, token, agreement_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/get_client_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getClientServicesAgreement = async (accountno, token, agreement_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/get_client_service_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAgreementSequence = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "clients/get_client_agreement_sequence",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const editClientAgreement = async (
  accountno,
  token,
  agreement_title,
  agreement_instruction,
  isactive,
  ismandatory,
  agreement_content,
  agreement_id,
  agreement_type,
  signatory_id,
  edit_type,
  selectedClients,
  max_seqno,
  isLocked,
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/edit_client_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_title: agreement_title,
            agreement_instruction: agreement_instruction,
            isactive: isactive,
            ismandatory: ismandatory,
            agreement_content: agreement_content,
            agreement_type: agreement_type,
            agreement_id: agreement_id,
            signatory_id: signatory_id,
            edit_type: edit_type,
            selectedServices: selectedClients,
            seqno: max_seqno,
            isLocked: isLocked,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const editClientServiceAgreement = async (
  accountno,
  token,
  agreement_title,
  agreement_instruction,
  isactive,
  ismandatory,
  agreement_content,
  agreement_id,
  agreement_type,
  signatory_id,
  edit_type,
  selectedClients,
  max_seqno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/edit_client_service_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_title: agreement_title,
            agreement_instruction: agreement_instruction,
            isactive: isactive,
            ismandatory: ismandatory,
            agreement_content: agreement_content,
            agreement_type: agreement_type,
            agreement_accountno: agreement_id,
            signatory_id: signatory_id,
            edit_type: edit_type,
            selectedClients: selectedClients,
            seqno: max_seqno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deleteClientAgreement = async (accountno, token, agreement_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/delete_client_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deleteClientServiceAgreement = async (accountno, token, agreement_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/delete_client_service_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const submitAgreementDefault = async (
  accountno,
  token,
  agreement_accountno,
  default_action
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "clients/change_client_agreement_default",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_accountno: agreement_accountno,
            default_action: default_action,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getClientInfo = async (accountno, token, client_accountno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/get_client_info",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const editClient = async (
  accountno,
  token,
  client_accountno,
  client_title,
  client_name,
  client_email,
  client_password,
  client_phone,
  client_comp_name,
  client_address,
  client_city,
  client_state,
  client_zip,
  client_country,
  notifyCheckBox,
  client_account_status,
  statusChanged,
  client_address2,
  client_isVerified
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/edit_client_info",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            title: client_title,
            name: client_name,
            email: client_email,
            password: client_password,
            phone: client_phone,
            comp_name: client_comp_name,
            address: client_address,
            address2: client_address2,
            city: client_city,
            state: client_state,
            zip: client_zip,
            country: client_country,
            notifyCheckBox: notifyCheckBox,
            client_account_status: client_account_status,
            statusChanged: statusChanged,
            client_isVerified: client_isVerified,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listServicesClients = async (accountno, token, agreement_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/list_services_clients",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const assignAgreementToServices = async (
  accountno,
  token,
  service_id,
  agreement_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "clients/assign_agreements_to_services",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            service_id: service_id,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listServiceAgreements = async (accountno, token, service_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agreements/list_service_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            service_id: service_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const assignServiceToAgreements = async (
  accountno,
  token,
  agreement_id,
  service_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "agreements/assign_service_to_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            service_id: service_id,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const revokeAgentSignedAgreements = async (
  accountno,
  token,
  agent_id,
  agreement_id,
  agreement_accountno,
  revoke_reason
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "agents/revoke_agent_signed_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
            agreement_id: agreement_id,
            agreement_accountno: agreement_accountno,
            revoke_reason: revoke_reason,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const approveAgentSignedAgreement = async (
  accountno,
  token,
  agent_id,
  approve_agreement_id,
  approve_agreement_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "agents/approve_agent_signed_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
            agreement_id: approve_agreement_id,
            agreement_accountno: approve_agreement_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const sendUnsignedAgentEmail = async (
  accountno,
  token,
  agent_id,
  agreement_id,
  agreement_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/send_unsigned_agent_email",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
            agreement_id: agreement_id,
            agreement_accountno: agreement_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listAssignedServicesAgreements = async (
  accountno,
  token,
  company_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "services/list_assigned_services_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            company_id: company_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAgreementPdf = async (accountno, token, agreement_file) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/get_agreement_pdf",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_file: agreement_file,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getClientSettings = async (accountno, token, client_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/get_client_welcome_data",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getClientKycDetail = async (accountno, token, client_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/get_client_kyc_detail",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const initiateClientKyc = async (accountno, token, client_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/initiate_client_kyc",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const emailKycDetails = async (
  accountno,
  token,
  client_id,
  kyc_email_address
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/email_kyc_details",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_id,
            kyc_email_address: kyc_email_address,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const submitKycApproval = async (
  accountno,
  token,
  company_id,
  kyc_reason,
  showApprovedAction
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/submit_kyc_approval",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: company_id,
            kyc_reason: kyc_reason,
            kyc_action: showApprovedAction,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getCreditApplications = async (accountno, token, client_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/get_credit_applications",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const rejectCreditApplication = async (
  accountno,
  token,
  client_accountno,
  application_no,
  rejection_reason
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/reject_credit_applications",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            application_no: application_no,
            rejection_reason: rejection_reason,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const approveCreditApplication = async (
  accountno,
  token,
  client_accountno,
  application_no
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/approve_credit_applications",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            application_no: application_no,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getClientArchivedAgreements = async (
  accountno,
  token,
  client_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/get_client_signed_archived",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getClientAssignedForms = async (
  accountno,
  token,
  client_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "forms/get_client_assigned_forms",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getClientArchivedForms = async (
  accountno,
  token,
  client_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "forms/get_client_archived_forms",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const viewClientFormSubmission = async (
  accountno,
  token,
  form_accountno,
  form_token,
  client_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "forms/get_client_assigned_form_single_submission",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            form_accountno: form_accountno,
            form_token: form_token,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const allClientRejections = async (
  accountno,
  token,
  form_accountno,
  form_token,
  client_accountno,
  submission_type
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "forms/get_client_form_rejections",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            form_accountno: form_accountno,
            form_token: form_token,
            submission_type: submission_type,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
            timeout: 10000,
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      //console.log("catch : ", error.message);
      if (
        error.code === "ECONNRESET" ||
        error.message.includes("socket hang up")
      ) {
        console.log("Socket hang-up detected.");
      } else {
        console.log("Error: ", error.message);
      }
    }
  });
  return res;
};

export const approveClientSubmission = async (
  accountno,
  token,
  form_accountno,
  id,
  form_title,
  client_submission_type,
  client_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "forms/approve_client_form_submission",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            form_token: id,
            form_accountno: form_accountno,
            form_title: form_title,
            submission_type: client_submission_type,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const unarchiveClientSubmission = async (
  accountno,
  token,
  form_accountno,
  id,
  client_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "forms/unarchive_client_form_submission",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            form_token: id,
            form_accountno: form_accountno,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const rejectClientSubmission = async (
  accountno,
  token,
  form_accountno,
  id,
  reason,
  form_title,
  client_submission_type,
  client_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "forms/reject_client_form_submission",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            form_token: id,
            form_accountno: form_accountno,
            form_title: form_title,
            submission_type: client_submission_type,
            client_accountno: client_accountno,
            reason: reason,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listAllForms = async (accountno, token, client_accountno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "forms/list_all_forms",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const assignFormToClients = async (
  accountno,
  token,
  selectedForms,
  client_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "forms/assign_form_to_single_client",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            selectedForms: selectedForms,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getClientArchivedAgreementsSingle = async (
  accountno,
  token,
  client_accountno,
  archive_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "clients/get_client_signed_archived_single",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            id: archive_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deleteClientArchivedAgreement = async (
  accountno,
  token,
  client_accountno,
  archive_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "clients/delete_client_signed_archived_single",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            id: archive_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deleteClientAssignedAgreement = async (
  accountno,
  token,
  client_accountno,
  archive_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "clients/delete_client_assigned_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            agreement_accountno: archive_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getServiceAgreement = async (
  accountno,
  token,
  client_accountno,
  service_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/get_service_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            service_id: service_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addNewClientServiceAgreement = async (
  accountno,
  token,
  client_accountno,
  archive_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "clients/add_new_client_assigned_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            agreement_accountno: archive_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const sendClientInvite = async (
  accountno,
  token,
  select_agent,
  client_email,
  client_name,
  invitation_comment
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/send_client_invite",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            select_agent: select_agent,
            client_email: client_email,
            client_name: client_name,
            invitation_comment: invitation_comment,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const resendActivation = async (accountno, token, client_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/resend_activation",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const clientLoginSso = async (accountno, token, client_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/client_single_sign_on",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const submitClientServicesData = async (
  accountno,
  token,
  client_id,
  welcome_content,
  email
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/submit_client_services_data",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_id,
            welcome_content: welcome_content,
            email: email,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const revokeClientSignedAgreements = async (
  accountno,
  token,
  client_id,
  agreement_accountno,
  revoke_reason
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "clients/revoke_client_signed_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_id: client_id,
            agreement_accountno: agreement_accountno,
            revoke_reason: revoke_reason,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const approveClientSignedAgreement = async (
  accountno,
  token,
  client_id,
  agreement_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "clients/approve_client_signed_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_id: client_id,
            agreement_accountno: agreement_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const sendUnsignedClientEmail = async (
  accountno,
  token,
  client_id,
  agreement_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/send_unsigned_client_email",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_id: client_id,
            agreement_accountno: agreement_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listBundleServices = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/list_bundle_services",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const updatePackageStatus = async (
  accountno,
  token,
  package_id,
  package_status
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/update_package_status",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            package_id: package_id,
            package_status: package_status,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listPackageAgreements = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agreements/list_package_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addPackageAgreement = async (
  accountno,
  token,
  agreement_title,
  agreement_instruction,
  isactive,
  ismandatory,
  agreement_content,
  agreement_type
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agreements/add_package_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_title: agreement_title,
            agreement_instruction: agreement_instruction,
            isactive: isactive,
            ismandatory: ismandatory,
            agreement_content: agreement_content,
            agreement_type: agreement_type,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getPackageAgreement = async (accountno, token, agreement_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agreements/get_package_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const editPackageAgreement = async (
  accountno,
  token,
  agreement_title,
  agreement_instruction,
  isactive,
  ismandatory,
  agreement_content,
  agreement_id,
  agreement_type
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agreements/edit_package_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_title: agreement_title,
            agreement_instruction: agreement_instruction,
            isactive: isactive,
            ismandatory: ismandatory,
            agreement_content: agreement_content,
            agreement_id: agreement_id,
            agreement_type: agreement_type,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listPackagesClients = async (accountno, token, agreement_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agreements/list_packages_clients",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const assignAgreementToPackages = async (
  accountno,
  token,
  service_id,
  agreement_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "agreements/assign_agreements_to_packages",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            service_id: service_id,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const unSignAgreement = async (
  accountno,
  token,
  agreement_id,
  service_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "agreements/unassign_agreements_to_packages",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            service_id: service_id,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listSubUsers = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "sub_users/list_sub_users",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addSubUser = async (
  accountno,
  token,
  name,
  email,
  password,
  phone,
  comp_name,
  title,
  address,
  city,
  state,
  zip,
  address2,
  country,
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "sub_users/add_sub_user",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            name: name,
            email: email,
            password: password,
            phone: phone,
            comp_name: comp_name,
            title: title,
            address: address,
            city: city,
            state: state,
            zip: zip,
            address2: address2,
            country: country
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSingleUser = async (accountno, token, id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "sub_users/get_sub_user",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            id: id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const editSubUser = async (
  accountno,
  token,
  name,
  email,
  password,
  phone,
  comp_name,
  title,
  user_id,
  account_status,
  address,
  address2,
  city,
  state,
  zip,
  country,
  notifyCheckBox
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "sub_users/edit_sub_user",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            name: name,
            email: email,
            password: password,
            phone: phone,
            comp_name: comp_name,
            title: title,
            user_id: user_id,
            account_status: account_status,
            address: address,
            address2: address2,
            city: city,
            state: state,
            zip: zip,
            country: country,
            notifyCheckBox: notifyCheckBox,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listUserRoles = async (accountno, token, user_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "sub_users/list_user_roles",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            user_id: user_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addSubUserRoles = async (
  accountno,
  token,
  user_id,
  main_roles,
  sub_roles
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "sub_users/add_user_roles",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            user_id: user_id,
            main_roles: main_roles,
            sub_roles: sub_roles,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const resellerLeftMenu = async (accountno, token, email) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "sub_users/get_reseller_left_menu",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            email: email,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getActivityLogs = async (accountno, token, email) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "sub_users/get_activity_logs",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            email: email,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAgentActivityLogs = async (accountno, token, email) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "sub_users/get_agent_activity_logs",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            email: email,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listCannedEmails = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "canned_emails/list_canned_emails",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getCannedEmail = async (accountno, token, email_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "canned_emails/get_email",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            email_id: email_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const editCannedEmail = async (
  accountno,
  token,
  email_title,
  email_subject,
  email_template,
  email_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "canned_emails/edit_email",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            email_title: email_title,
            email_subject: email_subject,
            email_template: email_template,
            email_id: email_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listAgentEmails = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "canned_emails/list_agent_canned_emails",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAgentCannedEmail = async (accountno, token, email_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "canned_emails/get_agent_email",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            email_id: email_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const editAgentEmail = async (
  accountno,
  token,
  email_title,
  email_subject,
  email_template,
  email_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "canned_emails/edit_agent_email",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            email_title: email_title,
            email_subject: email_subject,
            email_template: email_template,
            email_id: email_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAgentSignupLink = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resellers/get_super_agent_signup",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listAgentSharelinks = async (accountno, token, agent_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resellers/list_agent_sharelinks",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listProductAgreements = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/list_product_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAgentCommissionSettings = async (
  accountno,
  token,
  agent_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "resellers/get_agent_commision_settings",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getPaidCommissionLogs = async (
  accountno,
  token,
  agent_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "resellers/get_paid_commission_logs",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAgentCommissionStatistics = async (
  accountno,
  token,
  agent_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "resellers/get_agent_commision_statistics",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addFundsInAgentsAccount = async (
  accountno,
  token,
  agent_id,
  payment_amount
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "resellers/add_funds_in_agents_account",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
            payment_amount: payment_amount,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAgentOrders = async (
  accountno,
  token,
  agent_id,
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "resellers/get_all_agent_orders",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addAgentsCommissionSettings = async (
  accountno,
  token,
  agent_id,
  commission_type,
  commission_value_type,
  commission_value,
  apply_commission,
  draw_threshold
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "resellers/add_agents_commission_settings",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
            commission_type: commission_type,
            commission_value_type: commission_value_type,
            commission_value: commission_value,
            apply_commission: apply_commission,
            draw_threshold: draw_threshold
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listUnAssignedServices = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/listUnAssignedServices",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAdminSupportPage = async (accountno, token, pageUrl) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/get_admin_support",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            pageUrl: pageUrl
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getKnowledgeBase = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "cms/get_knowledge_base",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,

          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

/**** Service Type Calls ****/

export const listServicesTypes = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/listServicesTypes",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const archiveServiceTypeAction = async (
  accountno,
  token,
  service_type_id,
  archive
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/archiveservicetype",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            service_type_id: service_type_id,
            archive: archive,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listServicesTypes_single = async (
  accountno,
  token,
  service_type_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/listServicesTypes_single",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            service_type_id: service_type_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const editServiceType_single = async (
  accountno,
  token,
  product_title,
  product_desc,
  status,
  service_type_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/editServiceType_single",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            service_type_id: service_type_id,
            product_title: product_title,
            product_desc: product_desc,
            status: status,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addServiceType_single = async (
  accountno,
  token,
  product_title,
  product_desc,
  status
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/addServiceType_single",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            product_title: product_title,
            product_desc: product_desc,
            status: status,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSignatorySettings = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resellers/get_signatory_settings",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getActivityLog = async (accountno, token, email) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "sub_users/get_activity_log",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            email: email,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const readAllNotifications = async (accountno, token, email) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "sub_users/read_all_notifications",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            email: email,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const readAllAgentsNotifications = async (token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "sub_users/read_all_agent_notifications",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const readAllClientsNotifications = async (token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "sub_users/read_all_client_notifications",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getActivityLogsAgent = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "sub_users/get_activity_logs_agents",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getActivityLogsClient = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "sub_users/get_activity_logs_clients",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

//Quotation Agreements
export const listQuotationAgreements = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "agreements/list_quotation_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deleteQuotationAgreement = async (
  accountno,
  token,
  agreement_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "agreements/delete_quotation_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addQuotationAgreement = async (
  accountno,
  token,
  agreement_title,
  agreement_instruction,
  isactive,
  ismandatory,
  agreement_content,
  agreement_type
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agreements/add_quotation_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_title: agreement_title,
            agreement_instruction: agreement_instruction,
            isactive: isactive,
            ismandatory: ismandatory,
            agreement_content: agreement_content,
            agreement_type: agreement_type,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getQuotationAgreement = async (accountno, token, agreement_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agreements/get_quotation_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const editQuotationAgreement = async (
  accountno,
  token,
  agreement_title,
  agreement_instruction,
  isactive,
  ismandatory,
  agreement_content,
  agreement_id,
  agreement_type
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agreements/edit_quotation_agreement",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agreement_title: agreement_title,
            agreement_instruction: agreement_instruction,
            isactive: isactive,
            ismandatory: ismandatory,
            agreement_content: agreement_content,
            agreement_type: agreement_type,
            agreement_id: agreement_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const check_integrations = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/check_integrations",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const sendSubUserInvite = async (
  accountno,
  token,
  subuser_email,
  subuser_name,
  subuser_title,
  invitation_comment,
  savedRoles
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "sub_users/send_sub_user_invite",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            subuser_email: subuser_email,
            subuser_name: subuser_name,
            subuser_title: subuser_title,
            invitation_comment: invitation_comment,
            savedRoles: savedRoles
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const checkResllerAccount = async (data) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_API_URL + "sub_users/check_reseller_account",
      JSON.stringify(data),
      axiosConfig
    );

    return response;
  } catch (error) {
    console.error("Error in checkResellerAccount:", error);
    throw error;
  }
};

export const deleteInviteUser = async (accountno, token, user_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "sub_users/delete_invited_user",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            user_id: user_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listInvitedUsers = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "sub_users/listInvitedUsers",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const setDefault = async (accountno, token, service_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/setDefault",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            service_id: service_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listDefaultServicesType = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "services/listDefaultServicesType",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const addNewClient = async (
  accountno,
  token,
  title,
  name,
  client_email,
  client_password,
  client_phone,
  client_company,
  client_address,
  client_address2,
  client_city,
  client_state,
  client_zip,
  client_country,
  agent_accountno,
  notify,
  account_status,
  passwordRequired
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/add_new_client",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            title: title,
            name: name,
            client_email: client_email,
            client_password: client_password,
            client_phone: client_phone,
            client_company: client_company,
            client_address: client_address,
            client_address2: client_address2,
            client_city: client_city,
            client_state: client_state,
            client_zip: client_zip,
            client_country: client_country,
            agent_accountno: agent_accountno,
            notify: notify,
            account_status: account_status,
            passwordRequired: passwordRequired,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deleteInviteClient = async (accountno, token, client_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/delete_invited_client",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_id: client_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const sendAgentInvite = async (
  accountno,
  token,
  agent_email,
  agent_name,
  invitation_comment
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/send_agent_invite",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_email: agent_email,
            agent_name: agent_name,
            invitation_comment: invitation_comment,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deleteInviteAgent = async (accountno, token, agent_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/delete_invited_agent",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listAgentAgreementsArchived = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "agents/list_agent_agreements_archived",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listAgntPermissions = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resellers/list_agent_permissions",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveAgentPermissions = async (accountno, token, selectedNames) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resellers/save_agent_permissions",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            selectedNames: selectedNames,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listClientPermissions = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resellers/list_client_permissions",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const saveClientPermissions = async (accountno, token, fillerID) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "resellers/save_client_permissions",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            fillerID: fillerID,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getEmailPreview = async (accountno, token, email_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "canned_emails/get_email_preview",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            email_id: email_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAgentCannedEmailPreview = async (
  accountno,
  token,
  email_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "canned_emails/get_agent_email_preview",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            email_id: email_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const updateClientPremission = async (
  accountno,
  token,
  client_accountno,
  status
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/updateClientPremission",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            status: status,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const updateSubClientPremission = async (
  accountno,
  token,
  client_accountno,
  status
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/updateSubClientPremission",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            status: status,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSubClientIntegrationData = async (
  accountno,
  token,
  client_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/getSubClientIntegrationData",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const updateClientPremissionMeta = async (
  accountno,
  token,
  client_accountno,
  status
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/updateClientPremissionMeta",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            status: status,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};



export const updateSubClientPremissionMeta = async (
  accountno,
  token,
  client_accountno,
  status
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/updateSubClientPremissionMeta",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            status: status,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listUserRolesNew = async (accountno, token, user_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "sub_users/list_user_roles_new",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            user_id: user_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};


export const sendSubClientInvite = async (
  accountno,
  token,
  client_email,
  client_name,
  invitation_comment,
  client_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/send_sub_client_invite",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_email: client_email,
            client_name: client_name,
            invitation_comment: invitation_comment,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};


export const addNewSubClient = async (
  accountno,
  token,
  title,
  name,
  client_email,
  client_password,
  client_phone,
  client_company,
  client_address,
  client_address2,
  client_city,
  client_state,
  client_zip,
  client_country,
  notify,
  account_status,
  passwordRequired,
  client_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/add_new_sub_client",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            title: title,
            name: name,
            client_email: client_email,
            client_password: client_password,
            client_phone: client_phone,
            client_company: client_company,
            client_address: client_address,
            client_address2: client_address2,
            client_city: client_city,
            client_state: client_state,
            client_zip: client_zip,
            client_country: client_country,
            notify: notify,
            account_status: account_status,
            passwordRequired: passwordRequired,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const deleteInviteSubClient = async (accountno, token, client_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/delete_invited_sub_client",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_id: client_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getSingleAgentLoginDetail = async (accountno, token, agent_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/get_single_agent_login_detail",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_id: agent_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listAllClientAgreements = async (accountno, token, client_accountno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/list_all_agreements",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};



export const assignAgreementsToClients = async (
  accountno,
  token,
  selectedAgreements,
  client_accountno
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/assign_agreement_to_single_client",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            selectedAgreements: selectedAgreements,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const reassignClientArchivedAgreement = async (
  accountno,
  token,
  client_accountno,
  archive_id
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL +
          "clients/reassign_client_signed_archived_single",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_accountno,
            id: archive_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listAgentsSuper = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "agents/list_super_agents",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listClientsSuper = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/list_super_clients",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const listCompleteOrders = async (accountno, token) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/list_complete_orders",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getAgentRequests = async (accountno, token, orderno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "reports/get_agent_payment_requests",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const getBillingDetails = async (accountno, token, orderno) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "reports/get_billing_details",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            orderno: orderno,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};


export const insertSalesorder = async (
  accountno,
  token,
  agent_accountno,
  client_accountno,
  orders
) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "bulk_imports/insert_sales_order",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            agent_accountno: agent_accountno,
            client_accountno: client_accountno,
            orders: orders
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};

export const resendActivationSub = async (accountno, token, client_id) => {
  var res = await new Promise((resolve, reject) => {
    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "clients/resend_activation_sub",
          JSON.stringify({
            token: process.env.REACT_APP_API_TOKEN,
            api_accountno: process.env.REACT_APP_API_ACCOUNTNO,
            accountno: accountno,
            client_accountno: client_id,
          }),
          {
            headers: {
              Authorization: `bearer ${token}`,
              "Content-Type": "application/json;charset=UTF-8",
              "Access-Control-Allow-Origin": "http://localhost:3000",
              "Access-Control-Allow-Credentials": true,
              useCredentails: true,
            },
          }
        )
        .then((response) => {
          resolve(response);
        });
    } catch (error) {
      console.log("catch : ", error);
    }
  });
  return res;
};
