import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import { Provider } from "react-redux";
import { createStore } from "redux";
import reducer from "../src/reducer/index";
import { io } from "socket.io-client";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const socket = io("https://admin.hugeuc.me:3080/");

socket.on("connect", () => {
  console.log("Connected to Admin notification server", socket.id);
});

socket.on("newNotification", (notificationData) => {
  console.log("New notification received:", notificationData);
  toast(
    <>
      <strong>{notificationData.title}</strong>
      <div>{notificationData.description}</div>
    </>,
    {
      position: "top-right",
      autoClose: 9000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    }
  );
});



socket.on("disconnect", () => {
  console.log("Disconnected from Admin notification server");
});

const store = createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);


const checkForNewVersion = async () => {

  const currentVersion = localStorage.getItem('buildVersion');

  try {

    const response = await fetch('/version.json', { cache: 'no-store' });
    const { buildVersion } = await response.json();

    if (currentVersion) {
      if (currentVersion !== buildVersion) {
        console.log(`New version available: ${buildVersion}. Current version: ${currentVersion}.`);

        localStorage.setItem('buildVersion', buildVersion);
        if ('caches' in window) {
          caches.keys().then((names) => {
            names.forEach((name) => caches.delete(name));
          });
        }
        window.location.reload(true);
      } else {
        console.log(`App is up-to-date. Current version: ${buildVersion}.`);
      }
    } else {
      console.log(`Setting initial version: ${buildVersion}`);
      localStorage.setItem('buildVersion', buildVersion);
    }
  } catch (error) {
    console.error("Error checking app version:", error);
  }
};

checkForNewVersion();


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <ToastContainer />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
reportWebVitals();
