import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { LOGO, APP_LIVE_URL } from "./../../config/config";
import $ from "jquery";
import HelperClass from "./../../config/helperClass";
import Tooltip from "@material-ui/core/Tooltip";
const auth = new HelperClass();

const LeftSideBar = (props) => {
  const [isCollapsed, setIsCollapsed] = useState(() => {
    const saved = localStorage.getItem("sidebarCollapsed");
    return saved ? JSON.parse(saved) : false;
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [submenuStyles, setSubmenuStyles] = useState({});
  const [isTransitioning, setIsTransitioning] = useState(false);
  const menuRefs = useRef({});

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);

      if (mobile && isCollapsed) {
        setIsCollapsed(false);
        localStorage.setItem("sidebarCollapsed", "false");
        applyStyles(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, [isCollapsed]);

  const applyStyles = (isCollapsed) => {
    const content = document.querySelector(".nk-content");
    const sidebar = document.querySelector(".nk-sidebar");

    if (content && sidebar) {
      const collapsedStyles = {
        sidebar: {
          width: "6rem",
          transition: "width 0.3s ease-in-out",
        },
        content: {
          marginLeft: "6rem",
          width: "calc(100% - 6rem)",
          transition: "margin-left 0.3s ease-in-out, width 0.3s ease-in-out",
        },
      };

      const expandedStyles = {
        sidebar: {
          width: "",
          transition: "width 0.3s ease-in-out",
        },
        content: {
          marginLeft: "",
          width: "",
          transition: "margin-left 0.3s ease-in-out, width 0.3s ease-in-out",
        },
      };

      const styles = isCollapsed ? collapsedStyles : expandedStyles;

      Object.assign(sidebar.style, styles.sidebar);
      Object.assign(content.style, styles.content);

      if (isCollapsed) {
        localStorage.setItem("contentStyles", JSON.stringify(collapsedStyles));
      } else {
        localStorage.removeItem("contentStyles");
      }
    }
  };

  useEffect(() => {
    const applyStoredStyles = () => {
      const savedStyles = localStorage.getItem("contentStyles");
      if (savedStyles && isCollapsed) {
        applyStyles(true);
      }
    };

    applyStoredStyles();

    const observer = new MutationObserver(applyStoredStyles);

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => observer.disconnect();
  }, [isCollapsed]);

  const toggleSidebar = () => {
    if (!isMobile) {
      setIsTransitioning(true);
      const newCollapsedState = !isCollapsed;
      setIsCollapsed(newCollapsedState);
      localStorage.setItem(
        "sidebarCollapsed",
        JSON.stringify(newCollapsedState)
      );
      applyStyles(newCollapsedState);

      setTimeout(() => {
        setIsTransitioning(false);
      }, 300);
    }
  };

  function classCheck(id) {
    if (!isCollapsed) {
      var menu_items = $("#" + id);
      if (menu_items.hasClass("active")) {
        menu_items.removeClass("active");
        menu_items.removeClass("current-page");
      } else {
        menu_items.addClass("active");
        menu_items.addClass("current-page");
      }
    }
  }

  function activeCheck() {
    var menu_active = $(this);
    if (menu_active.hasClass("active")) {
      $(this).removeClass("active");
      $(this).removeClass("current-page");
    } else {
      $(this).addClass("active");
      $(this).addClass("current-page");
    }
  }

  const addMenuHistoryLeft = (link) => {
    props.mainProps.history.push(link);
  };

  const calculateSubmenuPosition = (index, parentElement) => {
    if (!parentElement) return {};

    const rect = parentElement.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const submenu = menuRefs.current[`submenu-${index}`];

    if (!submenu) return {};

    const originalDisplay = submenu.style.display;
    const originalVisibility = submenu.style.visibility;
    submenu.style.visibility = "hidden";
    submenu.style.display = "block";

    const submenuHeight = submenu.getBoundingClientRect().height;

    submenu.style.visibility = originalVisibility;
    submenu.style.display = originalDisplay;

    const spaceBelow = viewportHeight - rect.top;
    const wouldOverflowBottom = rect.top + submenuHeight > viewportHeight - 50;

    const baseStyles = {
      background: "white",
      display: "block",
      position: "fixed",
      left: "5.6rem",
      boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      borderRadius: "4px",
      padding: "0",
      zIndex: 1000,
      minWidth: "213px",
      maxWidth: "213px",
      border: "1px solid #f0f0f0",
    };

    if (wouldOverflowBottom) {
      const bottomOffset = Math.min(submenuHeight, viewportHeight - 40);

      baseStyles.bottom = "20px";
      delete baseStyles.top;

      if (submenuHeight > viewportHeight - 40) {
        baseStyles.maxHeight = `${viewportHeight - 40}px`;
        baseStyles.overflowY = "auto";
      }
    } else {
      baseStyles.top = rect.top;
    }

    return baseStyles;
  };

  const handleMenuHover = (index, element) => {
    if (isCollapsed) {
      requestAnimationFrame(() => {
        const styles = calculateSubmenuPosition(index, element);
        setSubmenuStyles((prev) => ({ ...prev, [index]: styles }));
      });
    }
  };

  const handleMenuLeave = (index) => {
    if (isCollapsed) {
      setSubmenuStyles((prev) => ({ ...prev, [index]: { display: "none" } }));
    }
  };

  return (
    <div
      className={`nk-sidebar nk-sidebar-fixed ${
        !isMobile && isCollapsed ? "collapsed" : ""
      }`}
      data-content="sidebarMenu"
      style={{
        width: !isMobile && isCollapsed ? "6rem" : "",
        transition: !isMobile ? "width 0.3s ease-in-out" : "none",
      }}
    >
      <div
        className="nk-sidebar-element nk-sidebar-head"
        style={{
          backgroundColor: "white",
          height: "63.5px",
          borderRight: "1px solid #e8ecec",
        }}
      >
        <div className="nk-sidebar-brand">
          <div className="logo-link nk-sidebar-logo">
            <img
              className="logo-light logo-img"
              src={
                auth.getUserLogo() && auth.getUserLogo() !== ""
                  ? APP_LIVE_URL + "files_data/logos/" + auth.getUserLogo()
                  : LOGO
              }
              alt="logo"
            />
            <img
              className="logo-dark logo-img"
              src={
                auth.getUserLogo() && auth.getUserLogo() !== ""
                  ? APP_LIVE_URL + "files_data/logos/" + auth.getUserLogo()
                  : LOGO
              }
              alt="logo-dark"
              style={{ marginLeft: "0" }}
            />
            <Tooltip
              title={isCollapsed ? "Expand" : "Collapse"}
              placement="left"
            >
              <span
                className="toggle-icon-mobile hover-bg-round"
                onClick={toggleSidebar}
                style={{
                  fontSize: "1rem",
                  position: "relative",
                  left: "6.8rem",
                  cursor: "pointer",
                }}
              >
                <em
                  className={`icon ni ${
                    isCollapsed ? "ni-menu-right" : "ni-menu"
                  }`}
                ></em>
              </span>
            </Tooltip>
          </div>
        </div>
        <div className="nk-menu-trigger mr-n2">
          <div
            className="nk-nav-toggle nk-quick-nav-icon d-xl-none"
            data-target="sidebarMenu"
          >
            <em className="icon ni ni-arrow-left"></em>
          </div>
        </div>
      </div>
      <div
        className="nk-sidebar-element"
        style={{ backgroundColor: "transparent" }}
      >
        <div
          className="nk-sidebar-content"
          style={{
            maxHeight: "calc(100vh - 64px)",
            overflowY: "hidden",
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.overflowY = "auto";
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.overflowY = "hidden";
          }}
        >
          <div
            className="nk-sidebar-menu"
            style={{
              padding: "0",
              margin: "0",
              listStyle: "none",
            }}
          >
            <ul className="nk-menu">
              <li
                className={isCollapsed ? "" : "nk-menu-heading"}
                style={{
                  margin: isCollapsed ? "5px" : undefined,
                  textAlign: isCollapsed ? "center" : undefined,
                }}
              >
                <h3
                  className="overline-title text-primary-alt"
                  style={{
                    color: "#6576ff",
                    padding: "16px 0px 0px",
                    fontSize: isCollapsed ? "9px" : undefined,
                  }}
                >
                  Admin Portal
                </h3>
              </li>
              <li
                className={isCollapsed ? "" : "nk-menu-heading"}
                style={{
                  margin: isCollapsed ? "5px" : undefined,
                  textAlign: isCollapsed ? "center" : undefined,
                }}
              >
                <h3
                  className="overline-title text-primary-alt"
                  style={{
                    color: "#202223",
                    fontSize: isCollapsed ? "9px" : undefined,
                    margin: isCollapsed ? "12px -3px 0px 0px" : undefined,
                  }}
                >
                  {auth.getCompName()}
                </h3>
              </li>

              {props.menuArr && props.menuArr.length > 0 ? (
                <>
                  {props.menuArr.map((mainMenu, index) => (
                    <>
                      {mainMenu.submenu && mainMenu.submenu.length > 0 ? (
                        <li
                          className="nk-menu-item has-sub"
                          onClick={() =>
                            classCheck(mainMenu.alt.replace(/[&\s]/g, ""))
                          }
                          id={mainMenu.alt.replace(/[&\s]/g, "")}
                          key={`mainmenu${index}`}
                          onMouseEnter={(e) =>
                            handleMenuHover(index, e.currentTarget)
                          }
                          F
                          onMouseLeave={() => handleMenuLeave(index)}
                        >
                          {isCollapsed ? (
                            <Tooltip title={mainMenu.alt} placement="top">
                              <div className="nk-menu-link nk-menu-toggle">
                                <span className="nk-menu-icon">
                                  <em
                                    className={mainMenu.tabimage}
                                    style={{
                                      color: isCollapsed
                                        ? "#6576ff"
                                        : undefined,
                                      transition: "color 0.3s ease-in-out",
                                    }}
                                  ></em>
                                </span>
                                {!isCollapsed && (
                                  <span
                                    className="nk-menu-text"
                                    style={{
                                      fontSize: "1.1rem",
                                      marginLeft: "5%",
                                      cursor: "pointer",
                                      opacity: isTransitioning ? 0 : 1,
                                      transition: "opacity 0.2s ease-in-out",
                                      transitionDelay: isCollapsed
                                        ? "0s"
                                        : "0.1s",
                                    }}
                                  >
                                    {mainMenu.alt}
                                  </span>
                                )}
                              </div>
                            </Tooltip>
                          ) : (
                            <div className="nk-menu-link nk-menu-toggle">
                              <span className="nk-menu-icon">
                                <em
                                  className={mainMenu.tabimage}
                                  style={{
                                    color: isCollapsed ? "#6576ff" : undefined,
                                    transition: "color 0.3s ease-in-out",
                                  }}
                                ></em>
                              </span>
                              {!isCollapsed && (
                                <span
                                  className="nk-menu-text"
                                  style={{
                                    fontSize: "1.1rem",
                                    marginLeft: "5%",
                                    cursor: "pointer",
                                    opacity: isTransitioning ? 0 : 1,
                                    transition: "opacity 0.2s ease-in-out",
                                    transitionDelay: isCollapsed
                                      ? "0s"
                                      : "0.1s",
                                  }}
                                >
                                  {mainMenu.alt}
                                </span>
                              )}
                            </div>
                          )}
                          <ul
                            ref={(el) =>
                              (menuRefs.current[`submenu-${index}`] = el)
                            }
                            className={`nk-menu-sub ${
                              isCollapsed ? "sub-menu-custom" : ""
                            }`}
                            style={
                              isCollapsed ? submenuStyles[index] || {} : {}
                            }
                          >
                            {mainMenu.submenu.map((datas, k) => (
                              <li className="nk-menu-item" key={`submenu${k}`}>
                                {datas.link === "/upgrades/general_settings" ||
                                datas.link === "/upgrades/smtp_settings" ||
                                datas.link === "/upgrades/payment_settings" ||
                                datas.link === "/upgrades/quotation_settings" ||
                                datas.link ===
                                  "/upgrades/commission_settings" ||
                                datas.link === "/upgrades/signatory_settings" ||
                                datas.link === "/upgrades/other_settings" ||
                                datas.link === "/support/adminSupport" ||
                                datas.link === "/support/knowledgeBase" ? (
                                  <a
                                    className="nk-menu-link"
                                    href={datas.link}
                                    style={{
                                      padding: isCollapsed ? 10 : undefined,
                                    }}
                                  >
                                    <span
                                      className="nk-menu-text"
                                      style={{ color: "#202223" }}
                                    >
                                      {datas.alt}
                                    </span>
                                  </a>
                                ) : (
                                  <Link
                                    className="nk-menu-link"
                                    to={datas.link}
                                    style={{
                                      padding: isCollapsed ? 10 : undefined,
                                    }}
                                  >
                                    <span
                                      className="nk-menu-text"
                                      style={{ color: "#202223" }}
                                    >
                                      {datas.alt}
                                    </span>
                                  </Link>
                                )}
                              </li>
                            ))}
                          </ul>
                        </li>
                      ) : (
                        <>
                          {isCollapsed ? (
                            <Tooltip title={mainMenu.alt} placement="top">
                              <li
                                className="nk-menu-item"
                                onClick={() => activeCheck()}
                                key={`mainmenu${index}`}
                              >
                                <Link
                                  className="nk-menu-link"
                                  to={mainMenu.link}
                                >
                                  <span className="nk-menu-icon">
                                    <em
                                      className={mainMenu.tabimage}
                                      style={{ color: "#6576ff" }}
                                    ></em>
                                  </span>
                                </Link>
                              </li>
                            </Tooltip>
                          ) : (
                            <li
                              className="nk-menu-item"
                              onClick={() => activeCheck()}
                              key={`mainmenu${index}`}
                            >
                              <Link className="nk-menu-link" to={mainMenu.link}>
                                <span className="nk-menu-icon">
                                  <em className={mainMenu.tabimage}></em>
                                </span>
                                <span
                                  className="nk-menu-text"
                                  style={{
                                    fontSize: "1.1rem",
                                    cursor: "pointer",
                                    opacity: isTransitioning ? 0 : 1,
                                    transition: "opacity 0.2s ease-in-out",
                                    transitionDelay: "0.1s",
                                  }}
                                >
                                  {mainMenu.alt}
                                </span>
                              </Link>
                            </li>
                          )}
                        </>
                      )}
                    </>
                  ))}
                </>
              ) : null}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftSideBar;
